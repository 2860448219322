// se mapea con el i18n "formato.fecha.corta" ( para ES = "dd/MM/yyyy" )
export const camposFechaCorta: string[] = [
    'fechaInicioCobertura',
    'fechaCreacion',
    'fechaNacimiento',
    'fecha',
    'fechaRenovacion',
    'fechaFirma',
    'ultimaAccion',
    'articulo.fecha'
];

// se mapea con el i18n "formato.fecha.hora" ( para ES = "dd/MM/yyyy HH:mm" )
export const camposFechaHora: string[] = [
    'fechaComunicacion',
];
