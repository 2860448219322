import { NamedType } from "src/app/core.models/named-type";
import { getFieldsFromInstance } from "src/app/core.utilidades/graphql-utils";

export class CalculosPoliza implements NamedType {
    // evita perder el nombre de clase cuando ivy realiza las optimizaciones de produccion
    _className: string = 'CalculosPoliza';

    id?: number;
    coeficienteCodigoPostal: number;
    factorDescuento: number;
    coeficienteRatioValorAgravamiento: number;
    coeficienteFallecimiento: number;
    coeficienteFallecimientoAccidente: number;
    coeficienteIpa: number;

    primaBrutaFallecimiento: number;
    ggeFallecimiento: number;
    ggiFallecimiento: number;
    pcFallecimiento: number;
    imcypFallecimiento: number;
    pcImcypFallecimiento: number;
    rcgFraccFallecimiento: number;
    sobreprimaFallecimiento: number;
    pnFallecimiento: number;

    primaBrutaFallecimientoAccidente: number;
    ggeFallecimientoAccidente: number;
    ggiFallecimientoAccidente: number;
    pcFallecimientoAccidente: number;
    imcypFallecimientoAccidente: number;
    pcImcypFallecimientoAccidente: number;
    rcgFraccFallecimientoAccidente: number;
    pnFallecimientoAccidente: number;

    primaBrutaIpa: number;
    ggeIpa: number;
    ggiIpa: number;
    pcIpa: number;
    imcypIpa: number;
    pcImcypIpa: number;
    rcgFraccIpa: number;
    sobreprimaIpa: number;
    pnIpa: number;

    pnTotal: number;
    consorcio: number;
    clea: number;
    ips: number;
    pt: number;
    primerRecibo: number;
    restoRecibo: number;

    edadActuarial: number;

    constructor() {
        this.coeficienteCodigoPostal = 0;
        this.factorDescuento = 0;
        this.coeficienteRatioValorAgravamiento = 0;
        this.coeficienteFallecimiento = 0;
        this.coeficienteFallecimientoAccidente = 0;
        this.coeficienteIpa = 0;

        this.primaBrutaFallecimiento = 0;
        this.ggeFallecimiento = 0;
        this.ggiFallecimiento = 0;
        this.pcFallecimiento = 0;
        this.imcypFallecimiento = 0;
        this.pcImcypFallecimiento = 0;
        this.rcgFraccFallecimiento = 0;
        this.pnFallecimiento = 0;

        this.primaBrutaFallecimientoAccidente = 0;
        this.ggeFallecimientoAccidente = 0;
        this.ggiFallecimientoAccidente = 0;
        this.pcFallecimientoAccidente = 0;
        this.imcypFallecimientoAccidente = 0;
        this.pcImcypFallecimientoAccidente = 0;
        this.rcgFraccFallecimientoAccidente = 0;
        this.sobreprimaFallecimiento = 0;
        this.pnFallecimientoAccidente = 0;

        this.primaBrutaIpa = 0;
        this.ggeIpa = 0;
        this.ggiIpa = 0;
        this.pcIpa = 0;
        this.imcypIpa = 0;
        this.pcImcypIpa = 0;
        this.rcgFraccIpa = 0;
        this.sobreprimaIpa = 0;
        this.pnIpa = 0;

        this.pnTotal = 0;
        this.consorcio = 0;
        this.clea = 0;
        this.ips = 0;
        this.pt = 0;
        this.primerRecibo = 0;
        this.restoRecibo = 0;
        this.edadActuarial = 0;

    }

    public static getFields = () => {
        return getFieldsFromInstance(new CalculosPoliza());
    };
}
