import { getFieldsFromInstance } from '../core.utilidades/graphql-utils';

export class TipoSeguro {

    id?: number;
    descripcion: string;
    
    constructor() {
        this.descripcion = '';
    }

    public static getFields = () => {
        return getFieldsFromInstance(new TipoSeguro());
    };
}