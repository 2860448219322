<div class="navbar-top">
    <div class="container">
        <div class="logo div-header">
            <img id="logo-colectivo" src="assets/images/logo-ASTRA.png" alt="ASTRA Seguros Logo">
        </div>
        <div class="div-header">
            <span>{{ 'area.saludo' | translate }} {{agenteLogado.nombreApellidos}}</span>
            <button class="btn-icon-blue logout" (click)="logout()">
                <mat-icon aria-hidden="false" aria-label="Logout" fontIcon="logout"
                    class="navbar-acciones-icon"></mat-icon>
                <span> {{ 'msg.general.logout' | translate }} </span>
            </button>
        </div>

    </div>
</div>