import { Component, OnInit } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConstantesApp } from 'src/app/app.config/constantes-app';
import { Agente } from 'src/app/app.domain.models/agente';
import { Cliente } from 'src/app/app.domain.models/cliente';
import { AgenteApiService } from 'src/app/app.domain.services/agente.api.service';
import { CoreEvents } from 'src/app/core.config/core-events';
import { DialogData } from 'src/app/core.models/modal/dialogData';
import { EventBrokerService } from 'src/app/core.services/event-broker.service';
import { UserService } from 'src/app/core.services/user.service';
import { SesionService } from 'src/app/core.services/sesion.service';

export const FORMATO_DATE_MES = {
    display: {
        dateInput: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-header-area-cliente',
    templateUrl: './header-area-cliente.component.html',
    providers: [{ provide: MAT_DATE_FORMATS, useValue: FORMATO_DATE_MES }],
})
export class HeaderAreaClienteComponent implements OnInit {
    menuAbierto: boolean = true;
    clienteLogado: Cliente;
    agente: Agente;

    constructor(
        private router: Router,
        private loginService: UserService,
        private eventBrokerService: EventBrokerService,
        private sesionService: SesionService,
        private agenteApiService: AgenteApiService,
        private toastr: ToastrService,
        private translateService: TranslateService,

    ) {
        this.clienteLogado = this.sesionService.getClienteLogado();
        this.agente = new Agente();
    }

    ngOnInit() {
        if (this.clienteLogado) {
            this.agenteApiService.obtenerByClienteId(this.clienteLogado.id!).then((agente: Agente) => {
                this.agente = agente;
            });
        } else {
            this.toastr.error(this.translateService.instant('mis-datos.error.cliente'));
        }
    }

    // Controla el icono del menu y notifica al padre
    toggleSideMenu() {
        // cambia el icono a traves del estado
        this.menuAbierto = !this.menuAbierto;
    }

    logout() {
        const actionTitle = 'login.cerrarSesion';
        const modalData = new DialogData(actionTitle, this, (context: HeaderAreaClienteComponent) => {
            context.loginService.logout().then(() => {
                context.router.navigate([ConstantesApp.PATH_USER_LOGIN]);
            });
        });
        this.eventBrokerService.publishEvent(CoreEvents.modalConfirm, modalData);
    }

    login() {
        this.router.navigateByUrl(ConstantesApp.PATH_USER_LOGIN);
    }

    home() {
        this.router.navigateByUrl(ConstantesApp.PATH_AREA_CLIENTE_INICIO);
    }

    misDatos() {
        this.router.navigateByUrl(ConstantesApp.PATH_AREA_CLIENTE_MIS_DATOS);
    }

    misPolizas() {
        this.router.navigateByUrl(ConstantesApp.PATH_AREA_CLIENTE_MIS_POLIZAS);
    }

    closeSubMenuMovil() {
        this.toggleSideMenu();
    }
}
