import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Login } from '../core.models/security/login';
import { UsuarioLogado } from '../core.models/security/usuarioLogado';
import { ConstantesCore } from '../core.utilidades/constantes-core';
import { SesionService } from './sesion.service';

const httpOptions = {
    headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
    }),
};
@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient, private sesion: SesionService) { }

    login(login: Login) {
        return this.http.post<UsuarioLogado>(ConstantesCore.ENDPOINT_LOGIN, JSON.stringify(login), httpOptions);
    }

    logout(): Promise<void> {
        const usuarioLogado = this.sesion.getUsuarioLogado();

        return new Promise((resolve) => {
            if (usuarioLogado) {
                this.sesion.clearSession();
            }
            resolve();
        });
    }

    public getUserToken(username: string) {
        return this.http.post<UsuarioLogado>(ConstantesCore.ENDPOINT_GET_USER_TOKEN, {username}, httpOptions);
    }

}
