<div class="container-modal">
    <div class="header-modal">
        <h1 class="text-color-primary"><strong>{{data.titulo | translate}}</strong></h1>
        <button mat-icon-button (click)="cerrar()" class="btn-icon-cerrar" aria-label="Cerrar">
            <mat-icon>close</mat-icon>
        </button>
        
    </div>
    <hr>
    <div class="body-modal">
        <div id="content-cobertura-modal">
            <p class="txt-m-1">
                {{data.mensaje| translate}}
            </p>
        </div>
    </div>
</div>
