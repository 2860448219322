import { Cliente } from '../app.domain.models/cliente';
import { TipoSexo } from '../app.domain.models/tipo-sexo';
import { getFieldsFromInstance } from '../core.utilidades/graphql-utils';
import { Archivo } from './archivo';

export class ArchivoCliente {
    id?: number;
    archivo: Archivo;
    cliente: Cliente;

    constructor() {
        this.cliente = new Cliente();
        this.archivo = new Archivo();
    }

    public static getFields = () => {
        const instancia = new ArchivoCliente();
        instancia.cliente = new Cliente();
        instancia.cliente.fechaNacimiento = new Date();
        instancia.cliente.tipoSexo = new TipoSexo();
        return getFieldsFromInstance(instancia);
    };
}
