import { AfterViewChecked, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericFormComponent } from '../generic-form/generic-form.component';

export const ModalMode = {
    MODE_READ: 'tipoLectura',
    MODE_EDIT: 'tipoModificacion',
    MODE_CONFIRM: 'tipoConfirmacion',
};

@Component({
    selector: 'app-modal-formulario-generico',
    templateUrl: './modal-formulario-generico.component.html',
})
export class ModalFormularioGenericoComponent implements AfterViewChecked {
    @ViewChild('generic', { read: false, static: false }) genericForm!: GenericFormComponent;

    readonly MODAL_MODE = ModalMode;
    
    constructor(
        public dialogRef: MatDialogRef<ModalFormularioGenericoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected cdRef: ChangeDetectorRef
    ) { }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
