import { getFieldsFromInstance } from '../core.utilidades/graphql-utils';
import { ArchivoCliente } from './archivoCliente';

export class Archivo {
    id?: number;
    nombre!: string;
    descripcion!: string;
    url!: string;
    fecha!: Date;
    tipo!: string;
    orden!: number;
    padreId!: number;
    archivos!: Archivo[];
    archivoClientes!: ArchivoCliente[];
    activo!: boolean;

    constructor() {
        this.orden = 0;
        this.nombre = '';
        this.descripcion = '';
        this.url = '';
        this.fecha = new Date();
        this.tipo = '';
        this.padreId = 0;
        this.activo = true;
        this.archivoClientes = [];
        this.archivos = [];
    }

    public static getFields = () => {
        return getFieldsFromInstance(new Archivo());
    };

}
