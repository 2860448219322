<div class="navbar-top">
    <div class="container">
        <div class="logo div-header">
            <img id="logo-colectivo" [src]="colectivoLogoUrl" [alt]="textoAlternativoLogo"> 
        </div>

        <div class="div-header">
            <a href="tel:{{ colectivo.agente.telefono }}" class="enlace-btn-telefono">
                <span class="material-symbols-outlined">phone_in_talk</span>
            </a>
            <p id="tlf"><a href="tel:{{ colectivo.agente.telefono }}" class="enlace-btn-telefono">{{ colectivo.agente.telefono }}</a></p>
            <p id="contacto" class="contacto-header" (click)="contacto()">{{ 'header.contacto' | translate }}</p>
            <a id="area_cliente" href="{{CONSTANTES_APP.PATH_USER_LOGIN}}" class="login-link">
                <span id="icono-area" class="material-symbols-outlined">account_circle</span>
                <p class="login-link">{{ 'header.area-cliente' | translate }}</p>
            </a>
            <button class="btn-icon-blue btn-login login-button" (click)="login()">
                <mat-icon class="login-icon">account_circle</mat-icon>
            </button>
        </div>
    </div>
</div>