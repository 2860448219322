import { DatePipe } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateInterceptorUtils } from './date-interceptor-utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class DateInterceptor implements HttpInterceptor {

    constructor(private datePipe: DatePipe, private translate: TranslateService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req instanceof HttpRequest && req.url && req.url.startsWith(environment.apiUrl)) {
            const body = req.body;
            DateInterceptorUtils.convertToISO(body, this.datePipe, this.translate);
        }
        return next.handle(req).pipe(map((val: HttpEvent<any>) => {
            if (val instanceof HttpResponse && val.url && val.url.startsWith(environment.apiUrl)) {
                const body = val.body;
                DateInterceptorUtils.convertToTs(body, this.datePipe, this.translate);
            }
            return val;
        }));
    }
}

