import { getFieldsFromInstance } from "../core.utilidades/graphql-utils";

export class TipoPeriodicidad {
    id?: number;
    descripcion: string;

    constructor() {
        this.descripcion = '';
    }

    public static getFields = () => {
        return getFieldsFromInstance(new TipoPeriodicidad());
    };
}