import { Injectable } from '@angular/core';
import { AppEvents } from '../app.config/app-events';
import { EventBrokerService } from '../core.services/event-broker.service';
import { Utilidades } from '../core.utilidades/utilidades';
import { EventHandlerCarritoApiService } from '../app.domain.services/event-handler-carrito.api.service';


@Injectable({
    providedIn: 'root',
})
export class AppEventsService {
    constructor(
        private eventBrokerService: EventBrokerService,
        private carritoService: EventHandlerCarritoApiService,
    ) { }

    // se registran y suscriben los diferentes eventos comunes de la aplicacion. Se llama desde app.component
    public registerEvents() {

        // registramos los eventos del framework
        Object.keys(AppEvents).forEach((eventName) => {
            this.eventBrokerService.registerEvent(eventName);
        });

    }

    public subscribeEvents() {
        this.eventBrokerService
            .subscribeEvent(AppEvents.previsualizarPDF)
            .subscribe((payload) => Utilidades.previsualizarPDF(payload));

        this.eventBrokerService
            .subscribeEvent(AppEvents.actualizarUltimaAccion)
            .subscribe((payload) => this.carritoService.actualizarPolizaUltimaAccion(payload));
            
        this.eventBrokerService
        .subscribeEvent(AppEvents.actualizarUltimaAccionIndividual)
        .subscribe((payload) => this.carritoService.actualizarUltimaAccionIndividual(payload));
    }

}
