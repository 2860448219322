import { inject } from '@angular/core';
import { Pagina } from '../core.components/datatable-generico/models/pagina';
import { PaginacionDatatable } from '../core.components/datatable-generico/models/paginacion-datatable';
import { ConfiguracionMostrarToast } from '../core.models/configuracion-mostrar-toast';
import { ApiService } from '../core.services/api.service';
import { getGQLQueryString } from '../core.utilidades/graphql-utils';

export class GenericGraphqlRepository<T> {
    protected apiService: ApiService;

    constructor(private query: any) {
        this.apiService = inject(ApiService);
    }

    obtenerById(id: number, configToast?: ConfiguracionMostrarToast): Promise<T> {
        const variables = { id };
        return this.apiService.query(getGQLQueryString(this.query.obtenerById, variables), variables, configToast);
    }
    
    listar(configToast?: ConfiguracionMostrarToast): Promise<T[]> {
        const variables = {};
        return this.apiService.query(getGQLQueryString(this.query.listar, variables), variables, configToast);
    }
    
    listarPaginado(paginacion: PaginacionDatatable, configToast?: ConfiguracionMostrarToast): Promise<Pagina<T>> {
        const variables = { paginacion };
        return this.apiService.query(getGQLQueryString(this.query.listarPaginado, variables), variables, configToast);
    }

    listarTotales(paginacion: PaginacionDatatable, configToast?: ConfiguracionMostrarToast): Promise<T[]> {
        const variables = { paginacion };
        return this.apiService.query(getGQLQueryString(this.query.listarTotales, variables), variables, configToast);
    }

    listarMaestro(dominio: string, configToast?: ConfiguracionMostrarToast): Promise<T[]> {
        const variables = { dominio };
        return this.apiService.query(getGQLQueryString(this.query.listarMaestro, variables), variables, configToast);
    }
    
    listarActivo(configToast?: ConfiguracionMostrarToast): Promise<T[]> {
        const variables = {};
        return this.apiService.query(getGQLQueryString(this.query.listarActivo, variables), variables, configToast);
    }
    
    crear(instancia: T, configToast?: ConfiguracionMostrarToast): Promise<T> {
        const variables = { instancia };
        return this.apiService.mutate(getGQLQueryString(this.query.crear, variables, this.query.paramType), variables, configToast);
    }
    
    actualizar(instancia: T, configToast?: ConfiguracionMostrarToast): Promise<T> {
        const variables = { instancia };
        return this.apiService.mutate(getGQLQueryString(this.query.actualizar, variables, this.query.paramType), variables, configToast);
    }
    
    desactivarById(id: any, configToast?: ConfiguracionMostrarToast): Promise<boolean> {
        const variables = { id };
        return this.apiService.mutate(getGQLQueryString(this.query.desactivarById, variables), variables, configToast);
    }
    
    activarById(id: number, configToast?: ConfiguracionMostrarToast): Promise<boolean> {
        const variables = { id };
        return this.apiService.mutate(getGQLQueryString(this.query.activarById, variables), variables, configToast);
    }
    
    eliminarById(id: any, configToast?: ConfiguracionMostrarToast): Promise<boolean> {
        throw new Error('Method not implemented.');
    }
    
    // startSubscription(): Observable<FetchResult<unknown, Record<string, any>, Record<string, any>>> {
    //     const variables = { };
    //     return this.apiService.subscribe(getGQLQueryString(this.query.subscription, variables), variables);
    // }
}
