<div class="navbar-top">
    <div class="container">
        <div class="logo div-header">
            <img id="logo-colectivo" 
                [src]="CONSTANTES.STATIC_CONTENT_PATH_RELATIVO_LOGO_INDIVIDUAL_AXA" 
                alt="logo-axa"
            > 
            <nav class="enlaces"  *ngIf="currentRoute === '/individual'" [ngClass]="{'abierto': menuAbierto}">
                <a (click)="scrollToElement('square-seguro-vida')"> {{'footer.enlaces.seguro-vida' | translate}} </a>
                <a (click)="scrollToElement('faq-2')"> {{'footer.enlaces.seguro-hipoteca' | translate}} </a>
                <a (click)="scrollToElement('square-faqs')"> {{'footer.enlaces.preguntas' | translate}} </a>
                <a (click)="scrollToElement('square-certificados')"> {{'footer.enlaces.nosotros' | translate}} </a>
            </nav>
        </div>

        <div class="div-header">
            <a href="tel: {{CONSTANTES.TELEFONO_AGENTE_INDIVIDUAL}}" class="enlace-btn-telefono">
                <span class="material-symbols-outlined">phone_in_talk</span>
            </a>
            <p id="tlf"><a href="tel: {{CONSTANTES.TELEFONO_AGENTE_INDIVIDUAL}}" class="enlace-btn-telefono">{{CONSTANTES.TELEFONO_AGENTE_INDIVIDUAL}}</a></p>
            <p id="contacto" class="contacto-header" (click)="contacto()">{{ 'header.contacto' | translate }}</p>
            <a id="area_cliente" href="{{CONSTANTES.PATH_USER_LOGIN}}" class="login-link">
                <span id="icono-area" class="material-symbols-outlined">account_circle</span>
                <p class="login-link">{{ 'header.area-cliente' | translate }}</p>
            </a>
            <button class="btn-icon-blue btn-login login-button" (click)="login()">
                <mat-icon class="login-icon">account_circle</mat-icon>
            </button>
            <div class="hamburguesa" id="hamburguesa" (click)="toggleSideMenu()">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</div>