import { NamedType } from "src/app/core.models/named-type";
import { getFieldsFromInstance } from "src/app/core.utilidades/graphql-utils";

export class CoeficienteIndTasaPura implements NamedType {
    // evita perder el nombre de clase cuando ivy realiza las optimizaciones de produccion
    _className: string = 'CoeficienteIndTasaPura';

    edad: number;
    fallecimiento: number;
    fallecimientoAccidente: number;
    incapacidad: number;
    capitalMaximoAutomatico: number;
    capitalMaximo: number;
    activo: boolean;
    
    constructor() {
        this.edad = 0;
        this.fallecimiento = 0;
        this.fallecimientoAccidente = 0;
        this.incapacidad = 0;
        this.capitalMaximoAutomatico = 0;
        this.capitalMaximo = 0;
        this.activo = false;
    }

    public static getFields = () => {
        const fields: string = getFieldsFromInstance(new CoeficienteIndTasaPura());
        return fields.split('id ').join('');
    };

}
