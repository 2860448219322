import { Injectable } from '@angular/core';
import { EventBrokerService } from 'src/app/core.services/event-broker.service';
import { GenericFormEventHandlerService } from './generic-form-event-handler.service';
import { GenericFormEvents } from './generic-form-events';

@Injectable({
    providedIn: 'root',
})
export class GenericFormEventsService {
    constructor(
        private eventBrokerService: EventBrokerService,
        private modalService: GenericFormEventHandlerService
    ) { }

    // se registran y suscriben los diferentes eventos comunes de la aplicacion. Se llama desde app.component
    public registerEvents() {

        // registramos los eventos del framework
        Object.keys(GenericFormEvents).forEach((eventName) => {
            this.eventBrokerService.registerEvent(eventName);
        });

    }

    public subscribeEvents() {
        // Diálogos
        this.eventBrokerService.subscribeEvent(GenericFormEvents.modalFormularioGenerico).subscribe((payload) => this.modalService.modalFormularioGenerico(payload));

    }
}
