import { getFieldsFromInstance } from "../core.utilidades/graphql-utils";

export class Beneficiario {
    id?: number;
    esBanco: boolean;
    nombreCompleto: string;
    nombreBanco: string;
    porcentaje: number;
    comunicarEmail: boolean;
    numeroPrestamo: string;
    email: string;
    polizaId?: number;
    clienteId: number;
    dni?: string;
    tipoRelacion?: string;
    orden?: number;

    constructor() {
        this.esBanco = false;
        this.nombreCompleto = '';
        this.nombreBanco = '';
        this.porcentaje = 0;
        this.comunicarEmail = false;
        this.numeroPrestamo = '';
        this.email = '';
        this.clienteId = 0;
        this.tipoRelacion = '';
        this.dni = '';
        this.orden = 0;
    }

    public static getFields = () => {
        return getFieldsFromInstance(new Beneficiario());
    };
}