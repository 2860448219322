import { NamedType } from '../core.models/named-type';
import { getFieldsFromInstance } from '../core.utilidades/graphql-utils';
import { Agente } from './agente';

export class TipoPoliza implements NamedType {

    _className = 'TipoPoliza';

    id?: number;
    descripcion: string;
    agente: Agente;
    nombre: string;

    constructor() {
        this.descripcion = '';
        this.nombre = '';
        this.agente = new Agente();
    }

    public static getFields = () => {
        return getFieldsFromInstance(new TipoPoliza());
    };
}