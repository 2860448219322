import { Archivo } from '../core.models/archivo';
import { getFieldsFromInstance } from '../core.utilidades/graphql-utils';

export class EmailTemplate {
    id?: number;

    asunto: string;
    cuerpo: string;
    archivos: Set<Archivo>;

    constructor() {
        this.asunto = '';
        this.cuerpo = '';
        this.archivos = new Set<Archivo>();
    }

    public static getFields = () => {
        return getFieldsFromInstance(new EmailTemplate());
    };
}
