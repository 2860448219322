import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo, MutationResult } from 'apollo-angular';
import { GraphQLError } from 'graphql';
import { gql } from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { ConfiguracionMostrarToast } from '../core.models/configuracion-mostrar-toast';
import { ConstantesCore } from '../core.utilidades/constantes-core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { AppGQLCacheConfig } from '../app.config/app-gql-cache';
import { GQLCacheService } from './gql-cache.service';

import { ApolloLink } from '@apollo/client/core';
import { onError } from "@apollo/client/link/error";
import * as CryptoJS from 'crypto-js';
import { ConstantesApp } from '../app.config/constantes-app';
import { Utilidades } from '../core.utilidades/utilidades';

const GRAPHQL_DATA_CONTENT = 'content';

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    private static STATUS_ERROR = 0;
    private static STATUS_OK = 1;

    constructor(
        private router: Router,
        private apollo: Apollo,
        private toastr: ToastrService,
        private translateService: TranslateService,
        private gqlCacheService: GQLCacheService,
        private httpLink: HttpLink,
        private httpClient: HttpClient
    ) { }

    /**
     * Llamada query genérica de GraphQL.
     *
     * @param gqlQuery Query
     * @param values Valores
     */
    public query<T>(gqlQuery: any, values: any, configToast?: ConfiguracionMostrarToast): Promise<T> {
        gqlQuery = gqlQuery.replace(/(\r\n|\n|\r)/g, ' ');
        const variables = Utilidades.deletePropiedadesUnderscore(values);

        let payloadObj = { query: gqlQuery, variables };
        let payloadJson = JSON.stringify(payloadObj);
        payloadJson = payloadJson.replace(/\s+/g, '');

        const requestToken = this.generateRequestToken(payloadJson);

        return new Promise((resolve, reject) => {
            const regExp = new RegExp('^query[^{]*{[^:]*:\\s*(?<queryMethod>[a-zA-z0-9]+).*$');
            const regExpExecArray = regExp.exec(gqlQuery);
            const queryMethod =
                regExpExecArray && regExpExecArray.groups && regExpExecArray.groups['queryMethod'] ? regExpExecArray.groups['queryMethod'] : null;

            let cacheData: T;
            if (AppGQLCacheConfig.includes(queryMethod!)) {
                cacheData = this.gqlCacheService.getCache(queryMethod!, variables) as T;
            }

            if (cacheData!) {
                resolve(cacheData);
            } else {
                const uri = environment.apiUrl + '/graphql';

                // Create a custom Apollo Link with the headers
                const httpLinkWithHeaders = this.httpLink.create({
                    uri,
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${requestToken}`,
                    }),
                });
                this.apollo.client.setLink(httpLinkWithHeaders);

                this.apollo.query({
                    query: gql(gqlQuery),
                    variables: JSON.parse(JSON.stringify(variables)),
                })
                    .subscribe({
                        next: (res) => {
                            if (res.errors) {
                                this.gestionarRespuestaErrores(res.errors, reject, configToast);
                            } else if (res.data) {
                                this.gestionarToast(ApiService.STATUS_OK, configToast);
                                if (AppGQLCacheConfig.includes(queryMethod!)) {
                                    this.gqlCacheService.setCache(queryMethod!, variables, res.data[GRAPHQL_DATA_CONTENT as keyof {}]);
                                }
                                resolve(res.data[GRAPHQL_DATA_CONTENT as keyof {}]);
                            }
                        },
                        error: (error) => this.flujoError(error, reject)
                    });
            }
        });
    }

    /**
     * Llamada mutation genérica de GraphQL.
     *
     * @param gqlMutation Mutation
     * @param values Valores
     */
    public mutate<T>(gqlMutation: any, values: any, configToast?: ConfiguracionMostrarToast): Promise<T> {
        gqlMutation = gqlMutation.replace(/(\r\n|\n|\r)/g, ' ');

        const variables = Utilidades.deletePropiedadesUnderscore(values);

        let payloadObj = {
            query: gqlMutation,
            variables: variables,
        };

        let payloadJson = JSON.stringify(payloadObj);

        payloadJson = payloadJson.replace(/\s+/g, '');

        const requestToken = this.generateRequestToken(payloadJson);

        return new Promise((resolve, reject) => {
            const uri = environment.apiUrl + '/graphql';

            const httpLinkWithHeaders = this.httpLink.create({
                uri,
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${requestToken}`,
                }),
            });

            const errorLink = onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors)
                    graphQLErrors.forEach(({ message, locations, path }) =>
                        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                    );
                if (networkError) console.log(`[Network error]: ${networkError}`);
            });

            const linkWithErrorHandling = ApolloLink.concat(errorLink, httpLinkWithHeaders);
            this.apollo.client.setLink(linkWithErrorHandling);

            this.apollo
                .mutate({
                    mutation: gql(gqlMutation),
                    variables: JSON.parse(JSON.stringify(variables)),
                })
                .subscribe({
                    next: (result: MutationResult<unknown>) => {
                        if (result.errors) {
                            this.gestionarRespuestaErrores(result.errors, reject, configToast);
                        } else if (!(result.data as MutationResult<T>)['content']) {
                            this.gestionarToast(ApiService.STATUS_ERROR, configToast);
                        } else if (result.data) {
                            this.gestionarToast(ApiService.STATUS_OK, configToast);
                            resolve(result.data[GRAPHQL_DATA_CONTENT as keyof {}]);
                        }
                    },
                    error: (error: any) => {
                        this.flujoError(error, reject, configToast);
                    }
                });
        });
    }

    /**
     * Gestiona el flujo de error.
     */
    private flujoError(error: { networkError: { status: number } }, reject: { (reason?: any): void; }, configToast?: ConfiguracionMostrarToast) {
        if (error.networkError && error.networkError.status === ConstantesCore.NO_AUTORIZADO) {
            console.warn('Warning API: ', error);
            this.router.navigate([ConstantesApp.PATH_USER_LOGIN]);
        } else {
            this.gestionarToast(ApiService.STATUS_ERROR, configToast);
        }
        reject(error);
    }

    private gestionarRespuestaErrores(errors: readonly GraphQLError[], reject: { (reason?: any): void; }, configToast?: ConfiguracionMostrarToast) {
        this.gestionarToast(ApiService.STATUS_ERROR, configToast);

        const primerError = errors[0];
        reject(primerError.message);
    }

    private gestionarToast(status: number, configToast?: ConfiguracionMostrarToast) {
        if (!configToast) {
            configToast = new ConfiguracionMostrarToast();
            configToast.mensajeError = this.translateService.instant('toast.generic.error');
        }
        if (status === ApiService.STATUS_ERROR && configToast.notificarError) {
            this.toastr.error(this.translateService.instant('' + configToast.mensajeError));
        }
        if (status === ApiService.STATUS_OK && configToast.notificarOK) {
            this.toastr.success(this.translateService.instant('' + configToast.mensajeOK));
        }
    }

    public get<T>(url: string, configToast?: ConfiguracionMostrarToast): Promise<T> {
        const httpOptions = {
            // headers: new HttpHeaders({ Authorization: 'Bearer ' + this.sesion.getToken() }),
            headers: new HttpHeaders({ responseType: 'text' }),
        };
        return new Promise((resolve, reject) => {
            this.httpClient.get<T>(url, httpOptions)
                .subscribe({
                    next: (result) => {
                        this.gestionarToast(ApiService.STATUS_OK, configToast);
                        resolve(result);
                    },
                    error: (reason: any) => {
                        this.flujoError(reason, reject, configToast)
                        reject(reason);
                    }
                });

        });
    }

    public post<T>(url: string, formData: FormData, configToast?: ConfiguracionMostrarToast): Promise<T> {
        if (!configToast) {
            configToast = new ConfiguracionMostrarToast();
            configToast.mensajeError = this.translateService.instant('toast.generic.error');
        }

        const httpOptions = {
            headers: new HttpHeaders()
                // .set('Authorization', 'Bearer ' + this.generateRequestToken())
                .set('Content-Type', 'application/json')
        };

        return new Promise((resolve, reject) => {
            this.httpClient.post<T>(url, formData, httpOptions)
                .subscribe({
                    next: (res) => {
                        this.gestionarToast(ApiService.STATUS_OK, configToast);
                        resolve(res);
                    },
                    error: (reason: any) => {
                        this.gestionarToast(ApiService.STATUS_ERROR, configToast);
                        reject(reason);
                    }
                });
        });
    }

    /**
     * Genera un token firmado que incluye los datos y un timestamp.
     * @param data Datos a firmar
     * @returns Token generado
     */
    public generateRequestToken(data: string): string {
        const secretKey = environment.tokenSecret;

        const header = {
            alg: "HS256",
            typ: "JWT",
        };

        const dataSinEspacios = data.replace(/\s+/g, '');
        const queryChecksum = this.generateChecksum(dataSinEspacios);

        const payload = {
            checksum: queryChecksum,
            timestamp: Date.now(),
        };

        const encodedHeader = CryptoJS.enc.Base64url.stringify(
            CryptoJS.enc.Utf8.parse(JSON.stringify(header))
        );
        const encodedPayload = CryptoJS.enc.Base64url.stringify(
            CryptoJS.enc.Utf8.parse(JSON.stringify(payload))
        );

        const signature = CryptoJS.HmacSHA256(`${encodedHeader}.${encodedPayload}`, secretKey);
        const encodedSignature = CryptoJS.enc.Base64url.stringify(signature);

        return `${encodedHeader}.${encodedPayload}.${encodedSignature}`;
    }

    /**
    * Genera un checksum basado en HMAC-SHA256.
    * @param data Cadena a firmar (aquí es la query, normalizada sin saltos).
    */
    public generateChecksum(data: string): string {
        const queryNormalizada = data.replace(/,/g, '');
        const secretKey = environment.tokenSecret;
        const hash = CryptoJS.HmacSHA256(queryNormalizada, secretKey);
        return CryptoJS.enc.Hex.stringify(hash);
    }
}
