import { EnvioEmail } from "../app.domain.models/envio-email";
import { EnvioEmailQuery } from "../app.domain.queries/envio-email.query";
import { GenericGraphqlRepository } from "../core.domain.repository/generic-graphql.repository";
import { GenericEntityRepository } from "../core.utilidades/generic-entity.repository";

export class EnvioEmailRepository extends GenericGraphqlRepository<EnvioEmail> implements GenericEntityRepository<EnvioEmail>
{
    constructor() {
        super(EnvioEmailQuery);
    }
}
