import { formatDate } from '@angular/common';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { Archivo } from '../core.models/archivo';
import { ConstantesCore } from './constantes-core';

export const DATE_SEPARATOR = '/';

export class Utilidades {

    /**
     * Cifra una cadena en SHA256 con 'sal' y la devuelve en base 64.
     *
     * @param cadena Cadena a cifrar.
     */
    static cifrarPassword(password: string): string {
        const byteString = CryptoJS.SHA256(ConstantesCore.SHA256_SALT + password);
        const base64 = CryptoJS.enc.Base64.stringify(byteString);
        return base64;
    }

    static clonarObjeto(objeto: any) {
        return objeto ? JSON.parse(JSON.stringify(objeto)) : null;
    }

    // Obtiene la fecha actual en el formato DD-MM-YYYY
    static getDateDDMMYYYY() {
        return formatDate(new Date(), ConstantesCore.DATE_FORMAT_DD_MM_YYYY, ConstantesCore.UNICODE_ES);
    }
    // Obtiene la fecha actual en el formato DD-MM-YYYY
    static getDateDDMMYYYYfromJSDate(fecha: Date) {
        return formatDate(fecha, ConstantesCore.DATE_FORMAT_DD_MM_YYYY_slash, ConstantesCore.UNICODE_ES);
    }

    /**
     * Obtener una fecha a partir de un valor, si es formato europeo mes y año están intercambiados
     */
    static getDate(value: any, noneuro?: boolean): Date {
        if (!noneuro && typeof value === 'string' && value.indexOf(DATE_SEPARATOR) > -1) {
            const parts = value.split(DATE_SEPARATOR);
            value = parts[1] + DATE_SEPARATOR + parts[0] + DATE_SEPARATOR + parts[2];
        }

        return new Date(value);
    }

    static slashDateToKebabDate(fecha: string) {
        const diaMesAnyo = fecha.split('/');
        return diaMesAnyo.join('-');
    }

    public static esFechaValidaDate(fecha: Date): boolean {
        return Utilidades.getMomentFromDiaMesAnio(fecha.getDate(), fecha.getMonth() + 1, fecha.getFullYear()).isValid();
    }
    public static esFechaValidaDiaMesAnio(dia: number, mes: number, anio: number): boolean {
        return Utilidades.getMomentFromDiaMesAnio(dia, mes, anio).isValid();
    }

    public static getMomentFromDiaMesAnio(dia: number, mes: number, anio: number) {
        // Concatenatar a cadena de YYYY-MM-DD, parsear y validar con momentJs
        const fechaString = `${anio}-${mes}-${dia}`;
        return moment(fechaString, 'YYYY-MM-DD');
    }

    public static calcularEdadDate(fechaNacimiento: Date): number {
        const fechaNacimientoMoment = Utilidades.getMomentFromDiaMesAnio(fechaNacimiento.getDate(), fechaNacimiento.getMonth() + 1, fechaNacimiento.getFullYear());
        const hoy = moment();
        return hoy.diff(fechaNacimientoMoment, 'years');
    }
    public static calcularEdadDiaMesAnio(dia: number, mes: number, anio: number): number {
        // Calcular la edad
        const hoy = moment();
        return hoy.diff(Utilidades.getMomentFromDiaMesAnio(dia, mes, anio), 'years');
    }

    public static calcularEdadActuarial(fechaNacimiento: Date): number {
        const hoy = new Date();
        const fechaAjustada = new Date(fechaNacimiento);
        
        fechaAjustada.setMonth(fechaAjustada.getMonth() - 6);
    
        fechaAjustada.setDate(fechaAjustada.getDate() + 1);
    
        let edad = hoy.getFullYear() - fechaAjustada.getFullYear();
    
        if (hoy < new Date(hoy.getFullYear(), fechaAjustada.getMonth(), fechaAjustada.getDate())) {
            edad -= 1;
        }

        return edad;
    }

    // public static calcularEdadActuarial(fechaNacimiento: Date): number {
    //     const hoy = new Date();
    //     let edad = Utilidades.calcularEdadDate(fechaNacimiento);
    //     const fechaCumpleanios = new Date(hoy.getFullYear(), fechaNacimiento.getMonth(), fechaNacimiento.getDate());
    //     const fechaCumpleActuarial = new Date(fechaCumpleanios);
    //     fechaCumpleActuarial.setMonth(fechaCumpleActuarial.getMonth() + 6);
    //     fechaCumpleActuarial.setDate(fechaCumpleActuarial.getDate() + 1);

    //     let diferenciaDias = hoy.getDate() - fechaCumpleanios.getDate();

    //     if (hoy.getMonth() > fechaCumpleActuarial.getMonth() || hoy.getMonth() === fechaCumpleActuarial.getMonth() && diferenciaDias > 0) {
    //         edad += 1;
    //     }
    //     console.log(edad);

    //     return edad;
    // }

    /**
     * Abre archivo en una nueva pestaña descargando desde el servidor
     *
     * @param fileURL url de blob de archivo
     */
    static mostrarArchivo(fileURL: string) {
        window.open(fileURL, '_blank');
    }

    /**
     * Obtener un id aleatorio
     */
    static getRandomId(): string {
        return Math.random().toString(36).substr(2, 10);
    }

    /**
     * Transformar entidad raíz para localizar datos de propiedades anidadas
     *
     * @param root entidad raíz
     * @param path ruta de la propiedad
     */
    public static transformEntityData(root: any, path: string) {
        const segs = path.split('.');

        while (segs.length > 1) {
            const pathStep = '' + segs.shift();

            if (typeof root[pathStep] === 'undefined' || root[pathStep] === null) {
                root[pathStep] = {};
            }
            root = root[pathStep];
        }

        return root;
    }

    /**
     * Obtener nodo hijo de una propiedad
     *
     * @param path ruta de la propiedad
     */
    public static getModelLeaf(path: string) {
        const segs = path.split('.');
        return segs[segs.length - 1];
    }

    /**
     * redondeo al numero de decimales indicado.
     * Si no se indica, redondea a 2 decimales.
     */
    public static redondear(numero: number, decimales?: number): number {
        if (decimales === null || decimales === undefined) {
            decimales = 2;
        }
        return Math.round((numero + Number.EPSILON) * Math.pow(10, decimales)) / Math.pow(10, decimales);
    }

    /**
     * Comprobar si un control de formulario tiene valor
     *
     * @param form formulario que contiene el control
     * @param name nombre de control
     */
    public static isCompleted(form: FormGroup, name: string) {
        const value = form.controls[name].value;

        return value !== undefined && value != null && value !== '';
    }


    /**
     * Comprueba que una hora de fin sea mayor que una hora de inicio
     *
     * @param mayor hora de fin
     * @param menor hora de inicio
     */
    public static esHoraFinMayorQueInicio(mayor: any, menor: any) {
        let resultado = true;
        if (+menor[0] === +mayor[0]) {
            resultado = +menor[1] < +mayor[1];
        } else {
            resultado = +menor[0] < +mayor[0];
        }
        return resultado;
    }

    public static camelCaseToDotNet(input: string): string {
        return input.split('.').map(segment => segment.charAt(0).toUpperCase() + segment.slice(1)).join('.');
    }

    public static adaptarBooleanToString(value: boolean): string {
        return value ? 'Si' : 'No';
    }

    /**
     *  Abre en una nueva pestaña del navegador la visualización de un archivo PDF.
     *
     * @param pdf archivo a visualizar.
     */
    static previsualizarPDF(pdf: Archivo) {
        window.open(environment.staticContentPath + pdf.url);
    }

    static rellenarPlantilla(plantilla: string, variables) {
        plantilla = Utilidades.procesarItemList(variables, plantilla);
        const fillTemplate = () =>
            new Function('return `' + plantilla + '`;').call(variables);

        return fillTemplate();
    }

    /**
     * Si la plantilla tiene que dibujar un listado de objetos, se agrega a la plantilla
     * las lineas necesarias ya transformadas
     *
     * @param variables Variables entrada
     * @param plantilla Plantilla utilizada
     */
    private static procesarItemList(variables: any, plantilla: string) {
        if (variables.list) {
            const inicio = ConstantesCore.PLANTILLA_LIST_INICIO;
            const fin = ConstantesCore.PLANTILLA_LIST_FIN;
            const list = variables.list;
            let subPlantilla = plantilla.substring(plantilla.indexOf(inicio), plantilla.indexOf(fin));
            subPlantilla = subPlantilla.replace(inicio, '');
            let subPlantillaResultado = '';
            list.forEach(element => {
                subPlantillaResultado += this.rellenarPlantilla(subPlantilla, element);
            });
            const subPlantillaReemplazar = plantilla.substring(plantilla.indexOf(inicio), plantilla.indexOf(fin));
            plantilla = plantilla.replace(subPlantillaReemplazar, subPlantillaResultado);
            plantilla = plantilla.replace(fin, '');
        }
        return plantilla;
    }

    /**
     * 
     * @param valor Valor en String
     * @returns Retornará de tipo number con el punto retirando la comma
     */
    public static transformPointToCommaDecimal(valor: any): any {
        if (valor == "" || valor == undefined || valor == null) return 0;
        return valor.toString().replace(/\./g, ",");
    }

    /**
     * 
     * @param valor Valor en String
     * @returns Retornará de tipo number con el punto retirando la comma
     */
    public static transformCommaToPointDecimal(valor: any): number {
        if (valor == "" || valor == undefined || valor == null) return 0;
        return parseFloat(valor.toString().replace(/\,/g, "."));
    }



    public static getFormControlName(control: FormControl): string | null {
        const controles = control.parent!.controls;
        for (const name of Object.keys(controles as keyof { [key: string]: AbstractControl<any, any>; } | AbstractControl<any, any>[])[0]) {
            if (controles[name] === control) {
                return name;
            }
        }
        return null;
    }

    /**
     * Devuelve los mensajes de error estandarizados
     * TODO revisar tipos de validaciones
     */
    public static getErrorFormControl(formControl: FormControl, translateService: TranslateService): string[] {

        const errores: string[] = [];
        if (formControl.errors) {

            //nombre del formcontrol
            const formControlName = Utilidades.getFormControlName(formControl);

            // Errores actuales
            const erroresFormControl = Object.keys(formControl.errors as keyof {});

            let value;
            erroresFormControl.forEach(key => {
                switch (key) {
                    case 'pattern':
                    case 'required':
                    case 'maxLength':
                    case 'min':
                    case 'max':
                        errores.push(translateService.instant(key));
                        break;

                    default:
                        errores.push(translateService.instant(key) + value[Object.keys(value)[0]]);
                        break;
                }
            });
        }
        return errores;
    }

    public static deletePropiedadesUnderscore(instancia: any): any {

        const instanciaLimpia = Utilidades.clonarObjeto(instancia);
        for (const key in instanciaLimpia) {
            if (typeof instanciaLimpia[key] === 'object') {
                instanciaLimpia[key] = Utilidades.deletePropiedadesUnderscore(instanciaLimpia[key]);
            } else if (key.startsWith('_')) {
                delete instanciaLimpia[key];
            }
        }

        return instanciaLimpia;
    }





    //FIXME: BORRAR
    /****************************************************************************************************** */
    public static getName(control: AbstractControl): string | null {
        let group = <FormGroup>control.parent;

        if (!group) {
            return null;
        }

        let name: string = '';

        Object.keys(group.controls).forEach(key => {
            let childControl = group.get(key);

            if (childControl !== control) {
                return;
            }

            name = key;
        });

        return name;
    }

    public static obtenerErrorFormControl(data: any, formulario: string) {
        let name = Utilidades.getName(data as FormControl);
        let message = "";
        if (data.errors != null) {
            let keys = Object.keys(data.errors);

            for (let index = 0; index < keys.length; index++) {
                switch (keys[index]) {
                    case "required":
                    case "pattern":
                    case "maxlength":
                    case "minlength":
                    case "caducidadTarjeta":
                    case "min":
                        message = `${formulario}.form.${keys[index]}.${name}`;
                        break;
                    default:
                        break;
                }
                if (message != "") {
                    break;
                }
            }
        }

        return message;
    }
    /****************************************************************************************************** */

}
