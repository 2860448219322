import { Injectable } from "@angular/core";
import { Poliza } from "../app.domain.models/poliza";
import { PolizaApiService } from "../app.modules/individual/domain.services/poliza.api.service";

@Injectable({
    providedIn: 'root',
})
export class EventHandlerCarritoApiService {
    constructor(
        private polizaService: PolizaApiService,
    ) { }

    actualizarPolizaUltimaAccion(poliza: Poliza) {
        this.polizaService.actualizarUltimaPantalla(poliza.id!, poliza.ultimaPantalla).catch(error => {
            console.error('Error al actualizar el carrito abandonado', error);
        });
    }

    actualizarUltimaAccionIndividual(poliza: Poliza) {
        this.polizaService.actualizarUltimaPantallaIndividual(poliza.id!, poliza.ultimaPantalla).catch(error => {
            console.error('Error al actualizar el carrito abandonado', error);
        });
    }
}
