import { getFieldsFromInstance } from '../core.utilidades/graphql-utils';

export class TipoSexo {

    id?: number;
    descripcion: string;
    
    constructor() {
        this.descripcion = '';
    }

    public static getFields = () => {
        return getFieldsFromInstance(new TipoSexo());
    };
}