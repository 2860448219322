import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ConstantesApp } from 'src/app/app.config/constantes-app';
import { SesionService } from '../../core.services/sesion.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {

    constructor(private sessionService: SesionService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.comprobarPermisoRuta(next);
    }

    comprobarPermisoRuta(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (
            // Comprobar que el usuario ha hecho login
            this.sessionService.getUsuarioLogado() &&
            // y tiene permiso para navegar al componente
            this.sessionService
                .getUsuarioLogado().roles.includes(route.data['expectedRole'])
        ) {
            return true;
        } else {
            this.router.navigate([ConstantesApp.PATH_FORBIDDEN]);
            return false;
        }
    }
}
