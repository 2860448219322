import { NamedType } from "src/app/core.models/named-type";
import { getFieldsFromInstance } from "src/app/core.utilidades/graphql-utils";

export class CoeficienteIndImc implements NamedType {
    // evita perder el nombre de clase cuando ivy realiza las optimizaciones de produccion
    _className: string = 'CoeficienteIndImc';

    imc: number;
    fuma: boolean;
    valor: number;
    activo: boolean;
    
    constructor() {
        this.imc = 0;
        this.fuma = false;
        this.valor = 0;
        this.activo = false;
    }

    public static getFields = () => {
        const fields: string = getFieldsFromInstance(new CoeficienteIndImc());
        return fields.split('id ').join('');
    };

}
