import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { ThemeService } from './app.domain.services/theme.service';
import { AppEventsService } from './app.services/app-events.service';
import { GenericFormEventsService } from './core-modules/generic-form/services/generic-form-events.service';
import { CoreEventsService } from './core.services/core-events.service';
declare var gtag: Function;

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

    constructor(
        private translate: TranslateService,
        @Inject(LOCALE_ID) private locale: string,
        private coreEventsService: CoreEventsService,
        private appEventsService: AppEventsService,
        private genericFormEventsService: GenericFormEventsService,
        private router: Router, private themeService: ThemeService
    ) {
        // establecemos el idioma por defecto para el translator
        this.translate.setDefaultLang(this.locale);

        // registra los eventos
        this.coreEventsService.registerEvents();
        this.appEventsService.registerEvents();
        this.genericFormEventsService.registerEvents();

        // inicia las suscripciones de los eventos
        this.coreEventsService.subscribeEvents();
        this.appEventsService.subscribeEvents();
        this.genericFormEventsService.subscribeEvents();

    }

    ngOnInit() {
        this.router.events.pipe(
            filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.aplicarTemaSegunRuta(event.urlAfterRedirects);
            gtag('config', 'G-MG6JBKYWY8', {
                page_path: event.urlAfterRedirects
              });
        });
        /**
         * este proyecto no usa sidebar dinamico
         */
        // // configuramos las rutas de navegacion de la aplicacion a partir de la rutas base de approutingmodule
        // // y las rutas de aplicacion definidas en app.config / app - rutas:
        // // 1. localiza el path con el context root de la aplicacion
        // const noderoot = baseapproutes.find((route) => route.path === '');
        // // 2. anida como hijos las rutas de la aplicacion
        // noderoot!.children = noderoot!.children!.concat(apprutas);
        // // 3. reinicia el router
        // this.router.resetconfig(baseapproutes);

    }

    aplicarTemaSegunRuta(url: string): void {
        if (url.includes('individual')) {
            this.themeService.setTheme('individual');
        } else {
            this.themeService.setTheme('default');
        }
    }

}
