import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-generic-field-button-next-back',
  templateUrl: './generic-field-button-next-back.component.html',
})
export class GenericFieldButtonNextBackComponent {

  @Input() direccion:string = "";
  @Input() texto:string = "";
  @Input() estilo:string = "simple";
  @Output() eventoClick:EventEmitter<any> = new EventEmitter<any>();

  clickEvent()
  {
    this.eventoClick.emit();
  }

}
