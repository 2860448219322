import { Injectable } from '@angular/core';
import { ConstantesCore } from 'src/app/core.utilidades/constantes-core';
import { ConstantesApp } from '../app.config/constantes-app';
import { Agente } from '../app.domain.models/agente';
import { Cliente } from '../app.domain.models/cliente';
import { Poliza } from '../app.domain.models/poliza';
import { Login } from '../core.models/security/login';
import { Permiso } from '../core.models/security/permiso';
import { UsuarioLogado } from '../core.models/security/usuarioLogado';
import { StorageService } from './storage.service';
import { CuestionarioSaludIndividual } from '../app.modules/individual/domain.models/cuestionario-salud-individual';

@Injectable({
    providedIn: 'root',
})
export class SesionService {
    private usuarioLogado!: UsuarioLogado;

    private token?: string;
    private login!: Login;
    private permisoList!: Permiso[];

    private cliente!: Cliente;
    private poliza!: Poliza;
    private polizaSaludIndividual!: CuestionarioSaludIndividual;
    
    private agenteLogado!: Agente;
    private clienteLogado!: Cliente;

    //FIXME: quitar flag
    private clienteNuevo = false;

    constructor(private storage: StorageService) {        
        this.reloadStorage();
    }

    public reloadStorage(): void {
        this.usuarioLogado = this.storage.loadObject(ConstantesCore.SESION_USUARIO_LOGADO);
        this.token = this.storage.loadString(ConstantesCore.SESION_TOKEN);
        this.login = this.storage.loadObject(ConstantesCore.SESION_LOGIN);
        this.permisoList = this.storage.loadObject(ConstantesCore.SESION_PERMISO_LIST);

        this.clienteNuevo = this.storage.loadObject(ConstantesCore.SESION_CLIENTE_NUEVO);
        this.cliente = this.storage.loadObject(ConstantesApp.SESION_CLIENTE);
        this.poliza = this.storage.loadObject(ConstantesApp.SESION_CUESTIONARIO);
        this.polizaSaludIndividual = this.storage.loadObject(ConstantesApp.SESION_CUESTIONARIO_SALUD_INDIVIDUAL);
        this.agenteLogado = this.storage.loadObject(ConstantesApp.SESION_AGENTE_LOGADO);
        this.clienteLogado = this.storage.loadObject(ConstantesApp.SESION_CLIENTE_LOGADO);
        this.poliza = this.storage.loadObject(ConstantesApp.SESION_POLIZA);
    }

    public setUsuarioLogado(usuario: UsuarioLogado): void {
        this.usuarioLogado = usuario;
        this.storage.saveObject(ConstantesCore.SESION_USUARIO_LOGADO, this.usuarioLogado);
        this.setToken(usuario.token);
    }

    public getUsuarioLogado(): UsuarioLogado {
        return this.usuarioLogado;
    }

    public setToken(token: string): void {
        this.token = token;
        this.storage.saveString(ConstantesCore.SESION_TOKEN, token);
    }

    public getToken() {
        return this.token;
    }

    public setLogin(login: Login) {
        this.login = login;
        this.storage.saveObject(ConstantesCore.SESION_LOGIN, login);
    }

    public getLogin(): Login {
        return this.login;
    }

    public clearSession() {
        const exceptKeys: string[] = [];
        this.storage.removeAllExcept(exceptKeys);
        this.reloadStorage();
    }

    public setPermisoList(permisoList: Permiso[]) {
        this.permisoList = permisoList;
        this.storage.saveObject(ConstantesCore.SESION_PERMISO_LIST, this.permisoList);
    }

    public getPermisoList(): Permiso[] {
        return this.permisoList;
    }

    public getPermisoListFromLocalStorage() {
        this.permisoList = this.storage.loadObject(ConstantesCore.SESION_PERMISO_LIST);
    }

    public getCliente(): Cliente {
        return this.cliente;
    }
    public setCliente(cliente: Cliente) {
        this.cliente = cliente;
        this.storage.saveObject(ConstantesApp.SESION_CLIENTE, cliente);
    }
    // public getPoliza(): Poliza {
    //     return this.poliza;
    // }
    // public setPoliza(poliza: Poliza) {
    //     this.poliza = poliza;
    //     this.storage.saveObject(ConstantesApp.SESION_CUESTIONARIO, poliza);
    // }
    
    public getCuestionarioSaludIndividual(): CuestionarioSaludIndividual {
        return this.polizaSaludIndividual;
    }
    
    public setCuestionarioSaludIndividual(polizaSaludIndividual: CuestionarioSaludIndividual) {
        this.polizaSaludIndividual = polizaSaludIndividual;
        this.storage.saveObject(ConstantesApp.SESION_CUESTIONARIO_SALUD_INDIVIDUAL, polizaSaludIndividual);
    }
    
    public getPoliza(): Poliza {
        return this.poliza;
    }
    
    public setPoliza(poliza: Poliza) {
        this.poliza = poliza;
        this.storage.saveObject(ConstantesApp.SESION_POLIZA, poliza);
    }

    public getExisteCliente(): boolean {
        return this.clienteNuevo;
    }
    public setClienteNuevo(existencia: boolean) {
        this.clienteNuevo = existencia;
        this.storage.saveObject(ConstantesApp.SESION_CLIENTE_NUEVO, existencia);
    }
    public setAgenteLogado(agente: Agente) {
        this.agenteLogado = agente;
        this.storage.saveObject(ConstantesApp.SESION_AGENTE_LOGADO, agente);
    }
    public getAgenteLogado(): Agente {
        return this.agenteLogado;
    }
    public setClienteLogado(cliente: Cliente) {
        this.clienteLogado = cliente;
        this.storage.saveObject(ConstantesApp.SESION_CLIENTE_LOGADO, cliente);
    }
    public getClienteLogado(): Cliente {
        return this.clienteLogado;
    }

}
