<div class="modal-generico">
    <div class="forms">
        <h1 mat-dialog-title>{{data.formTitle | translate}}</h1>
    <app-generic-form #genericForm [data]="data"></app-generic-form>
    </div>
    

    <div mat-dialog-actions *ngIf="data.modo === MODAL_MODE.MODE_EDIT; else actionsRead">
        <button class="btn-linea btn-secundario" (click)="onNoClick()">{{'generic-form.label.cancel' |
            translate}}</button>

        <button *ngIf="genericForm.isValid()" class="btn-fill" [mat-dialog-close]="genericForm.formGroup.value"
            cdkFocusInitial>{{'generic-form.label.guardar' | translate}}</button>
        <button *ngIf="!genericForm.isValid()" class="btn-fill" (click)="genericForm.formInvalido()"
            cdkFocusInitial>{{'generic-form.label.guardar' | translate}}</button>
    </div>
    <ng-template #actionsRead>
        <div mat-dialog-actions>
            <button class="btn-linea btn-secundario" (click)="onNoClick()">{{'generic-form.label.cancel' |
                translate}}</button>
        </div>
    </ng-template>
</div>