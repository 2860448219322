import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-informativo',
    templateUrl: './modal-informativo.component.html',
})
export class ModalInformativoComponent {


    constructor(public dialogRef: MatDialogRef<ModalInformativoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { titulo: string, mensaje: string },
        public dialog: MatDialog
    ) { }

    cerrar(): void {
        this.dialogRef.close({ ok: true });
    }


}
