import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalConfirmacionComponent } from '../core.components/modal-confirmacion/modal-confirmacion.component';
import { ModalInformativoComponent } from '../core.components/modal-informativo/modal-informativo.component';
import { Utilidades } from '../core.utilidades/utilidades';

@Injectable({
    providedIn: 'root',
})
export class EventHandlerCoreModalService {

    private dialogRefMap = new Map<string, MatDialogRef<any>>();

    constructor(protected matDialog: MatDialog) { }

    /**
     * Crear modal de confirmación
     */
    public modalConfirm(data: any) {
        // Comprobar si existe el diálogo previamente
        const id = 'modal-confirm';
        const dialog = this.matDialog.getDialogById(id);
        if (!dialog) {
            const dialogConfig = new MatDialogConfig();
            // The user can't close the dialog by clicking outside its body
            dialogConfig.disableClose = true;
            dialogConfig.id = id;
            dialogConfig.panelClass = ['modal-central', 'modal-central-estrecho'];
            const instanciaClone = data.instancia ? Utilidades.clonarObjeto(data.instancia) : null;
            dialogConfig.data = { entity: instanciaClone, formTitle: data.titulo };
            const modalDialog = this.matDialog.open(ModalConfirmacionComponent, dialogConfig);

            modalDialog.afterClosed().subscribe((result) => {
                this.modalCallback(result, data);
            });
        }
    }

    /**
     * Crear modal informativo
     */
    public modalInfo(data: { titulo: string, mensaje: string }) {
        // Comprobar si existe el diálogo previamente
        const id = 'modal-info';
        const dialog = this.matDialog.getDialogById(id);
        if (!dialog) {
            const dialogConfig = new MatDialogConfig();
            // The user can't close the dialog by clicking outside its body
            dialogConfig.disableClose = true;
            dialogConfig.id = id;
            dialogConfig.panelClass = ['modal-central', 'modal-central-estrecho'];
            dialogConfig.data = data;
            const modalDialog = this.matDialog.open(ModalInformativoComponent, dialogConfig);
            this.dialogRefMap.set(id, modalDialog);

            modalDialog.afterClosed().subscribe((result) => {
                this.modalCallback(result, data);
            });
        }
    }

    /**
     * Llamada de respuesta del modal
     */
    public modalCallback(result: any, data: any) {
        // si result viene relleno significa que ha entrado por ok
        if (result) {
            if (data.callback) {
                if (data.callbackParams) {
                    // eslint-disable-next-line no-eval
                    data.callback(eval(data.callbackParams), data.contexto);
                } else {
                    data.callback(data.contexto);
                }
            }
        } else {
            if (data.callbackCancelar) {
                if (data.callbackCancelarParams) {
                    // eslint-disable-next-line no-eval
                    data.callbackCancelar(eval(data.callbackCancelarParams), data.contexto);
                } else {
                    data.callbackCancelar(data.contexto);
                }
            }
        }
    }

}
