import { CalculoTasaBase } from "../domain.models/calculo-tasa-base";
import { Poliza } from "../../../app.domain.models/poliza";
import { PolizaQuery } from "../domain.queries/poliza.query";
import { GenericGraphqlRepository } from "../../../core.domain.repository/generic-graphql.repository";
import { ConfiguracionMostrarToast } from "../../../core.models/configuracion-mostrar-toast";
import { GenericEntityRepository } from "../../../core.utilidades/generic-entity.repository";
import { getGQLQueryString } from "../../../core.utilidades/graphql-utils";
import { CuestionarioSaludIndividual } from "../domain.models/cuestionario-salud-individual";

export class PolizaRepository extends GenericGraphqlRepository<Poliza> implements GenericEntityRepository<Poliza> {
    constructor() {
        super(PolizaQuery);
    }

    obtenerPolizasByClienteId(clienteId: number, configToast?: ConfiguracionMostrarToast): Promise<Poliza[]> {
        const variables = { clienteId };
        return this.apiService.query(getGQLQueryString(PolizaQuery.obtenerPolizasByClienteId, variables), variables, configToast);
    }

    calcularTasaBase(calculoTasaBase: CalculoTasaBase, configToast?: ConfiguracionMostrarToast): Promise<CalculoTasaBase> {
        const variables = { calculoTasaBase };
        return this.apiService.query(getGQLQueryString(PolizaQuery.calcularTasaBase, variables), variables, configToast);
    }

    calcularTasaBaseImc(calculoTasaBase: CalculoTasaBase, polizaId: number, configToast?: ConfiguracionMostrarToast): Promise<CalculoTasaBase> {
        const variables = { calculoTasaBase, polizaId };
        return this.apiService.query(getGQLQueryString(PolizaQuery.calcularTasaBaseImc, variables), variables, configToast);
    }

    actualizarUltimaPantallaIndividual(polizaId: number, ultimaPantalla: string, configToast?: ConfiguracionMostrarToast): Promise<Poliza> {
        const variables = { polizaId, ultimaPantalla };
        return this.apiService.query(getGQLQueryString(PolizaQuery.actualizarUltimaPantallaIndividual, variables), variables, configToast);
    }

    actualizarUltimaPantalla(polizaId: number, ultimaPantalla: string, configToast?: ConfiguracionMostrarToast): Promise<Poliza> {
        const variables = { polizaId, ultimaPantalla };
        return this.apiService.query(getGQLQueryString(PolizaQuery.actualizarUltimaPantalla, variables), variables, configToast);
    }

    actualizarBeneficiarioList(instancia: Poliza, configToast?: ConfiguracionMostrarToast): Promise<Poliza> {
        const variables = { instancia };
        return this.apiService.query(getGQLQueryString(PolizaQuery.actualizarBeneficiarioList, variables), variables, configToast);
    }

    calcularCoeficientePorCodigoPostal(polizaId: number, configToast?: ConfiguracionMostrarToast): Promise<Poliza> {
        const variables = { polizaId };
        return this.apiService.query(getGQLQueryString(PolizaQuery.calcularCoeficientePorCodigoPostal, variables), variables, configToast);
    }

    calcularCoeficienteFraccionamiento(polizaId: number, configToast?: ConfiguracionMostrarToast): Promise<Poliza> {
        const variables = { polizaId };
        return this.apiService.query(getGQLQueryString(PolizaQuery.calcularCoeficienteFraccionamiento, variables), variables, configToast);
    }

    calcularCoeficienteProfesionRiesgo(polizaId: number, configToast?: ConfiguracionMostrarToast): Promise<Poliza> {
        const variables = { polizaId };
        return this.apiService.query(getGQLQueryString(PolizaQuery.calcularCoeficienteProfesionRiesgo, variables), variables, configToast);
    }

    calcularAgravamientoPorElemento(polizaId: number, configToast?: ConfiguracionMostrarToast): Promise<Poliza> {
        const variables = { polizaId };
        return this.apiService.query(getGQLQueryString(PolizaQuery.calcularAgravamientoPorElemento, variables), variables, configToast);
    }

    aplicarDescuentoPoliza(polizaId: number, configToast?: ConfiguracionMostrarToast): Promise<Poliza> {
        const variables = { polizaId };
        return this.apiService.query(getGQLQueryString(PolizaQuery.aplicarDescuentoPoliza, variables), variables, configToast);
    }

    calcularPC(primaBruta: number, ggi: number, configToast?: ConfiguracionMostrarToast): Promise<number> {
        const variables = { primaBruta, ggi };
        return this.apiService.query(getGQLQueryString(PolizaQuery.calcularPC, variables), variables, configToast);
    }
    
    calcularPCIPA(primaBruta: number, ggi: number, ratio: number, factorCP: number, configToast?: ConfiguracionMostrarToast): Promise<number> {
        const variables = { primaBruta, ggi, ratio, factorCP };
        return this.apiService.query(getGQLQueryString(PolizaQuery.calcularPCIPA, variables), variables, configToast);
    }

    desactivarPolizasByClienteId(clienteId: number, configToast?: ConfiguracionMostrarToast): Promise<boolean> {
        const variables = { clienteId };
        return this.apiService.query(getGQLQueryString(PolizaQuery.desactivarPolizasByClienteId, variables), variables, configToast);
    }
    
    
}
