import { EnvioEmail } from "../app.domain.models/envio-email";
import { GQLQuerySpec, queryType } from "../core.utilidades/graphql-utils";

const type = EnvioEmail;
const typeName = 'EnvioEmail';

export const EnvioEmailQuery = {
    paramType: typeName,

    obtenerById: new GQLQuerySpec({
        queryType: queryType.QUERY,
        methodName: 'obtener' + typeName + 'ById',
        fields: type.getFields(),
    }),
    crear: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'crear' + typeName,
        fields: type.getFields(),
    }),
}