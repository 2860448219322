import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { setAppInjector } from 'src/app/app.injector';

@Component({
    selector: 'app-layout-back-office',
    templateUrl: './layout-back-office.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LayoutBackOfficeComponent {

    constructor(injector: Injector) {
        setAppInjector(injector)
    }

}
