import { EmailTemplate } from "../app.domain.models/email-template";
import { GQLQuerySpec, queryType } from "../core.utilidades/graphql-utils";

const type = EmailTemplate;
const typeName = 'EmailTemplate';

export const EmailTemplateQuery = {
    paramType: typeName,

    obtenerById: new GQLQuerySpec({
        queryType: queryType.QUERY,
        methodName: 'obtener' + typeName + 'ById',
        fields: type.getFields(),
    }),
}