import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { camposFechaCorta, camposFechaHora } from '../app.config/app-campos-fecha';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor() {}

    saveObject(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    saveString(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    // tipo devuelto
    // cambiado de Object any para el parche de permisoList
    loadObject(key: string): any {
        return JSON.parse(this.loadString(key), (key, value) => {
            if (camposFechaCorta.includes(key) || camposFechaHora.includes(key)) {
                return moment(value).toDate();
            } else {
                return value;
            }
        });
    }

    loadString(key: string): string {
        return '' + localStorage.getItem(key);
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }

    removeAll(): void {
        localStorage.clear();
    }

    // Elimina todas las entradas excepto aquellas keys pasadas en el array
    removeAllExcept(keyArray: string[]) {
        Object.keys(localStorage).forEach((storageKey) => {
            if (!keyArray.some((key) => key === storageKey)) {
                this.remove(storageKey);
            }
        });
    }
}
