import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GQLCacheService {
    private cache: Map<string, Map<string, any>> = new Map<string, Map<string, any>>();

    public setCache(query: string, params: string, value: any): void {
        const paramsString = this.getParamString(params);
        if (this.cache.has(query)) {
            this.cache.get(query)!.set(paramsString, value);
        } else {
            this.cache.set(query, new Map<string, any>([[paramsString, value]]));
        }
    }

    public getCache(query: string, params: string): any {
        const paramsString = this.getParamString(params);
        return this.cache.get(query) ? this.cache.get(query)!.get(paramsString) : undefined;
    }

    public removeCache(query: string, params?: string): void {
        if (params){
            const paramsString = this.getParamString(params);
            this.cache.get(query)!.delete(paramsString);
        } else {
            this.cache.delete(query);
        }
    }

    public removeAll(): void {
        this.cache.clear();
    }

    private getParamString(params: any) {
        return typeof params === 'string' ? params : JSON.stringify(params).replace(/\"/g, '');
    }
}
