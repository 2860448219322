
import { Agente } from "../app.domain.models/agente";
import { GQLQuerySpec, queryType } from "../core.utilidades/graphql-utils";

const type = Agente;
const typeName = 'Agente';

export const AgenteQuery = {
    paramType: typeName,

    listarPaginado: new GQLQuerySpec({
        queryType: queryType.QUERY_PAGINADA,
        methodName: 'listar' + typeName + 'Paginado',
        fields: type.getFields(),
    }),

    obtenerById: new GQLQuerySpec({
        queryType: queryType.QUERY,
        methodName: 'obtener' + typeName + 'ById',
        fields: type.getFields(),
    }),

    crear: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'crear' + typeName,
        fields: type.getFields(),
    }),

    actualizar: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'actualizar' + typeName,
        fields: type.getFields(),
    }),

    desactivarById: new GQLQuerySpec({
        queryType: queryType.MUTATION_NO_RESULT,
        methodName: 'desactivar' + typeName + 'ById',
        fields: type.getFields(),
    }),

    activarById: new GQLQuerySpec({
        queryType: queryType.MUTATION_NO_RESULT,
        methodName: 'activar' + typeName + 'ById',
        fields: type.getFields(),
    }),

    obtenerByEmail: new GQLQuerySpec({
        queryType: queryType.PERSONALIZADA,
        queryPersonalizada: 'query q ($email: String) { content: methodName# (email: $email) { fields# }  }',
        methodName: 'obtener' + typeName + 'ByEmail',
        fields: type.getFields(),
    }),

    obtenerByClienteId: new GQLQuerySpec({
        queryType: queryType.PERSONALIZADA,
        queryPersonalizada: 'query q ($clienteId: Long) { content: methodName# (clienteId: $clienteId) { fields# } }',
        methodName: 'obtener' + typeName + 'ByClienteId',
        fields: type.getFields(),
    }),
}
