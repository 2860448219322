export const CoreEvents = {
    // modales
    modalConfirm: 'modalConfirm',
    modalInfo: 'modalInfo',
    modalIrrecuperable: 'modalIrrecuperable',

    // spinner
    modalSpinnerShow: 'modalSpinnerShow',
    modalSpinnerHide: 'modalSpinnerHide',

    // datatables
    actualizarDatatable: 'actualizarDatatable',

    // single sign on
    login: 'login',
    logout: 'logout',

};
