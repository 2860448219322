<!-- Cabecera -->
<app-header-individual [menuAbierto]="menuAbierto" (reportarEstadoMenu)="actualizarEstadoMenu($event)">
</app-header-individual>

<!-- Contenido -->
<router-outlet></router-outlet>

<div class="custom-chat-container">
    <span class="custom-chat-text">{{ 'chatra.ayudamos' | translate}} </span>
    <div class="custom-chat-button">
        <img src="../../../../../assets/images/call-center.jpg" alt="Chat" />
    </div>
</div>
