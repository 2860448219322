import { Injectable } from '@angular/core';
import { CoreEvents } from 'src/app/core.config/core-events';
import { EventBrokerService } from './event-broker.service';
import { EventHandlerCoreModalService } from './event-handler-core-modal.service';

@Injectable({
    providedIn: 'root',
})
export class CoreEventsService {
    constructor(
        private eventBrokerService: EventBrokerService,
        private modalService: EventHandlerCoreModalService
        // private spinnerService: SpinnerService
    ) { }

    // se registran y suscriben los diferentes eventos comunes de la aplicacion. Se llama desde app.component
    public registerEvents() {

        // registramos los eventos del framework
        Object.keys(CoreEvents).forEach((eventName) => {
            this.eventBrokerService.registerEvent(eventName);
        });

    }

    public subscribeEvents() {
        // Diálogos
        // this.eventBrokerService.subscribeEvent(CoreEvents.modalFormularioGenerico).subscribe((payload) => this.modalService.modalFormularioGenerico(payload));
        this.eventBrokerService.subscribeEvent(CoreEvents.modalConfirm).subscribe((payload) => this.modalService.modalConfirm(payload));
        this.eventBrokerService.subscribeEvent(CoreEvents.modalInfo).subscribe((payload) => this.modalService.modalInfo(payload));

        // Spinner
        // this.eventBrokerService.subscribeEvent(CoreEvents.modalSpinnerShow).subscribe(() => this.spinnerService.modalSpinnerShow());
        // this.eventBrokerService.subscribeEvent(CoreEvents.modalSpinnerHide).subscribe(() => this.spinnerService.modalSpinnerHide());

    }
}
