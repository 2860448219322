import { Agente } from '../app.domain.models/agente';
import { AgenteQuery } from '../app.domain.queries/agente.query';
import { GenericGraphqlRepository } from '../core.domain.repository/generic-graphql.repository';
import { ConfiguracionMostrarToast } from '../core.models/configuracion-mostrar-toast';
import { GenericEntityRepository } from '../core.utilidades/generic-entity.repository';
import { getGQLQueryString } from '../core.utilidades/graphql-utils';

export class AgenteRepository extends GenericGraphqlRepository<Agente> implements GenericEntityRepository<Agente> {
    constructor() {
        super(AgenteQuery);
    }

    obtenerByEmail(email: string, configToast?: ConfiguracionMostrarToast): Promise<Agente> {
        const variables = { email };
        return this.apiService.query(getGQLQueryString(AgenteQuery.obtenerByEmail, variables), variables, configToast);
    }

    obtenerByClienteId(clienteId: number): Promise<Agente> {
        const variables = { clienteId };
        return this.apiService.query(getGQLQueryString(AgenteQuery.obtenerByClienteId, variables), variables);
    }

}
