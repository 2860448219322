import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConstantesCore } from 'src/app/core.utilidades/constantes-core';
import { HeaderColectivosComponent } from './header-colectivos/header-colectivos.component';

@Component({
    selector: 'app-layout-colectivos',
    templateUrl: './layout-colectivos.component.html',
    styleUrls: ['./layout-colectivos.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutColectivosComponent implements OnInit {

    public pantallaCompleta = false;
    public menuAbierto = true;

    @ViewChild(HeaderColectivosComponent, { read: false, static: false }) headerComponent!: HeaderColectivosComponent;

    constructor() {
    }

    ngOnInit() {
        this.calcularMenuAbiertoCerrado(window.innerWidth);
    }

    // Escucha los movimientos del navegador
    @HostListener('window:resize', ['$event'])
    onResize(event: { target: { innerWidth: number; }; }) {
        this.calcularMenuAbiertoCerrado(event.target.innerWidth);
    }

    private calcularMenuAbiertoCerrado(ancho: number) {
        // Inicia la visualización del menu inicial segun el ancho de pantalla.
        if (ancho <= ConstantesCore.SIDEBAR_CLOSE_MAX_RESOLUTION) {
            this.pantallaCompleta = true;
            this.menuAbierto = false;
        } else {
            this.pantallaCompleta = false;
            this.menuAbierto = true;
        }
    }

    // recibe el estado del menu desde un hijo y lo sincroniza a ambos hijos.
    public actualizarEstadoMenu(menuAbierto: boolean) {
        this.menuAbierto = menuAbierto;
    }
}
