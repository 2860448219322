import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutColectivosComponent } from '../app.components/colectivos/layout-colectivos/layout-colectivos.component';
import { ForbiddenComponent } from '../app.components/forbidden/forbidden.component';
import { UserLayoutComponent } from './user/componentes/user-layout/user-layout.component';
import { ConstantesApp } from '../app.config/constantes-app';
import { RoleGuard } from '../core.utilidades/guards/role.guard';
import { LayoutAreaClienteComponent } from '../app.components/area-cliente/layout-area-cliente/layout-area-cliente.component';
import { LayoutBackOfficeComponent } from '../app.components/back-office/layout-back-office/layout-back-office.component';
import { LayoutIndividualComponent } from './individual/components/layout-individual/layout-individual.component';


export const baseAppRoutes: Routes = [
    { path: '', redirectTo: 'individual', pathMatch: 'full' },
    // any user can activate
    { path: 'forbidden', component: ForbiddenComponent },
    {
        path: 'user',
        component: UserLayoutComponent,
        loadChildren: () => import('./user/module/user.module').then(m => m.UserModule)
    },
    {
        path: 'colectivos',
        component: LayoutColectivosComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./colectivos/colectivos.module').then(m => m.ColectivosModule)
            }
        ]
    },
    {
        path: 'individual',
        component: LayoutIndividualComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./individual/individual.module').then(m => m.IndividualModule)
            }
        ]
    },

    // AuthGuard
    {
        path: 'area-cliente',
        canActivate: [RoleGuard],
        data: { expectedRole: 'ROL_CLIENTE' },
        component: LayoutAreaClienteComponent,
        loadChildren: () => import('./area-cliente/area-cliente.module').then(m => m.AreaClienteModule)
    },
    
    {
        path: 'back-office',
        canActivate: [RoleGuard],
        data: { expectedRole: 'ROL_AGENTE' },
        component: LayoutBackOfficeComponent,
        loadChildren: () => import('./back-office/back-office.module').then(m => m.BackOfficeModule)
    },

    { path: '**', redirectTo: ConstantesApp.PATH_FORBIDDEN }, // cualquier otra ruta
];


@NgModule({
    imports: [
        RouterModule.forRoot(baseAppRoutes, {
            useHash: false,
            enableTracing: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
