import { Archivo } from "../core.models/archivo";
import { NamedType } from "../core.models/named-type";
import { getFieldsFromInstance } from "../core.utilidades/graphql-utils";
import { Agente } from "./agente";

export class Colectivo implements NamedType {

    _className = 'Colectivo';
    
    id?: number;
    url: string;
    nombre: string;
    color: string;
    archivoLogo: Archivo;
    agente: Agente;
    activo: boolean;

    constructor() {
        this.url = '';
        this.nombre = '';
        this.color = '';
        this.archivoLogo = new Archivo();
        this.activo = true;
        this.agente = new Agente();
    }
 
    public static getFields = () => {
        return getFieldsFromInstance(new Colectivo());
    };
}
