import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalContactoComponent } from 'src/app/app.components/modales/modal-contacto/modal-contacto.component';
import { ConstantesApp } from 'src/app/app.config/constantes-app';
import { EventBrokerService } from 'src/app/core.services/event-broker.service';

export const FORMATO_DATE_MES = {
    display: {
        dateInput: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-header-individual',
    templateUrl: './header-individual.component.html',
    providers: [{ provide: MAT_DATE_FORMATS, useValue: FORMATO_DATE_MES }],
})
export class HeaderIndividualComponent implements OnInit{
    readonly CONSTANTES = ConstantesApp;
    @Input() menuAbierto: boolean = false;
    @Output() reportarEstadoMenu = new EventEmitter<boolean>();
    currentRoute: string = '';


    constructor(
        private router: Router, 
        private eventBrokerService: EventBrokerService,
        public dialog: MatDialog,
    ) 
    {}

    ngOnInit(){
        this.menuAbierto = false;
        this.router.events.subscribe(() => {
            this.currentRoute = this.router.url;
          });
    }

    // Controla el icono del menu y notifica al padre
    toggleSideMenu() {
        // cambia el icono a traves del estado
        this.menuAbierto = !this.menuAbierto;
        //notifica al padre
        this.reportarEstadoMenu.emit(this.menuAbierto);
    }

    scrollToElement(id: string): void {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    contacto() {
        const dialogRefContactoUsuario = this.dialog.open(
            ModalContactoComponent,
            { panelClass: 'custom-modal-contacto', }
        );
        dialogRefContactoUsuario.afterClosed().subscribe((result) => { });
    }

    logout() {
        this.eventBrokerService.publishEvent("logout");
    }

    login() {
        this.router.navigateByUrl(ConstantesApp.PATH_USER_LOGIN);
    } 

    @HostListener('document:click', ['$event'])
    clickOut(event: MouseEvent) {
        const targetElement = event.target as HTMLElement;

        if (this.menuAbierto && !targetElement.closest('.hamburguesa')) {
            this.menuAbierto = false;
        }
    }

}
