import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { GenericFormEventsService } from 'src/app/core-modules/generic-form/services/generic-form-events.service';

@Component({
    selector: 'app-layout-area-cliente',
    templateUrl: './layout-area-cliente.component.html',
    styleUrls: ['./layout-area-cliente.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutAreaClienteComponent{

    constructor(
        private genericFormEventsService: GenericFormEventsService,
    ) {
        // registra los eventos
        this.genericFormEventsService.registerEvents();

        // inicia las suscripciones de los eventos
        this.genericFormEventsService.subscribeEvents();
    }

}
