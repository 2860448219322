import { NamedType } from "src/app/core.models/named-type";
import { getFieldsFromInstance } from "src/app/core.utilidades/graphql-utils";
import { CoeficienteIndImc } from "./coeficiente-ind-imc";
import { CoeficienteIndTasaPura } from "./coeficiente-ind-tasa-pura";

export class CalculoTasaBase implements NamedType {
    // evita perder el nombre de clase cuando ivy realiza las optimizaciones de produccion
    _className: string = 'CalculoTasaBase';

    edadActuarial: number;
    capitalFallecimiento: number;
    capitalFallecimientoAccidente: number;
    capitalIncapacidad: number;
    precioAnualFallecimiento: number;
    precioAnualFallecimientoAccidente: number;
    precioAnualIncapacidad: number;
    precioMensualFallecimiento: number;
    precioMensualFallecimientoAccidente: number;
    precioMensualIncapacidad: number;
    coeficienteIndImc: CoeficienteIndImc;
    coeficienteIndTasaPura: CoeficienteIndTasaPura;

    constructor() {
        this.edadActuarial = 0;
        this.capitalFallecimiento = 0;
        this.capitalFallecimientoAccidente = 0;
        this.capitalIncapacidad = 0;
        this.precioAnualFallecimiento = 0;
        this.precioAnualFallecimientoAccidente = 0;
        this.precioAnualIncapacidad = 0;
        this.precioMensualFallecimiento = 0;
        this.precioMensualFallecimientoAccidente = 0;
        this.precioMensualIncapacidad = 0;
        this.coeficienteIndImc = new CoeficienteIndImc();
        this.coeficienteIndTasaPura = new CoeficienteIndTasaPura();
    }

    public static getFields = () => {
        const fields: string = getFieldsFromInstance(new CalculoTasaBase());
        return fields.split('id ').join('');
    };

}
