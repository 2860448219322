import { EmailTemplate } from "../app.domain.models/email-template";
import { EmailTemplateQuery } from "../app.domain.queries/email-template.query";
import { GenericGraphqlRepository } from "../core.domain.repository/generic-graphql.repository";
import { GenericEntityRepository } from "../core.utilidades/generic-entity.repository";

export class EmailTemplateRepository extends GenericGraphqlRepository<EmailTemplate> implements GenericEntityRepository<EmailTemplate>
{
    constructor() {
        super(EmailTemplateQuery);
    }
}
