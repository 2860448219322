import { NgModule } from '@angular/core';
import { ApolloClientOptions, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';

const uri = environment.apiUrl + '/graphql';
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

    // Create a custom Apollo Link with the headers
    const httpLinkWithHeaders = httpLink.create({ uri });

    // Establece las opciones de caché
    const defaultOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    };

    return {
        link: httpLinkWithHeaders,
        cache: new InMemoryCache({ addTypename: false }),
        defaultOptions: defaultOptions
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule { }
