import { Archivo } from '../core.models/archivo';
import { getFieldsFromInstance } from '../core.utilidades/graphql-utils';

export class EnvioEmail {
    id?: number;

    destinatario: string;
    asunto: string;
    cuerpo: string;
    fecha: Date;
    enviado: number;
    timestampEnvio: Date;
    adjuntos: Archivo[];

    constructor() {
        this.destinatario = '';
        this.asunto = '';
        this.cuerpo = '';
        this.fecha = new Date();
        this.enviado = 0;
        this.timestampEnvio = new Date();
        this.adjuntos = [];
    }

    public static getFields = () => {
        return getFieldsFromInstance(new EnvioEmail());
    };
}
