import { CalculoTasaBase } from "../domain.models/calculo-tasa-base";
import { Poliza } from "../../../app.domain.models/poliza";
import { GQLQuerySpec, queryType } from "../../../core.utilidades/graphql-utils";
import { CuestionarioSaludIndividual } from "../domain.models/cuestionario-salud-individual";


const type = Poliza;
const typeName = 'Poliza';

export const PolizaQuery = {
    paramType: typeName,

    listarPaginado: new GQLQuerySpec({
        queryType: queryType.QUERY_PAGINADA,
        methodName: 'listar' + typeName + 'Paginado',
        fields: type.getFields(),
    }),

    obtenerById: new GQLQuerySpec({
        queryType: queryType.QUERY,
        methodName: 'obtener' + typeName + 'ById',
        fields: type.getFields(),
    }),

    calcularTasaBase: new GQLQuerySpec({
        queryType: queryType.QUERY,
        methodName: 'calcularTasaBase' + typeName,
        fields: CalculoTasaBase.getFields(),
    }),

    calcularTasaBaseImc: new GQLQuerySpec({
        queryType: queryType.PERSONALIZADA,
        methodName: 'calcularTasaBaseImc',
        queryPersonalizada: 'query q ($calculoTasaBase: CalculoTasaBaseInput!, $polizaId: Long!) { content: methodName# (calculoTasaBase: $calculoTasaBase, polizaId: $polizaId) { fields# } }',
        fields: CalculoTasaBase.getFields(),
    }),

    actualizar: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'actualizar' + typeName,
        fields: type.getFields(),
    }),

    crear: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'crear' + typeName,
        fields: type.getFields(),
    }),

    actualizarUltimaPantallaIndividual: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'actualizarUltimaPantallaIndividual',
        fields: type.getFields(),
    }),

    obtenerPolizasByClienteId: new GQLQuerySpec({
        queryType: queryType.PERSONALIZADA,
        queryPersonalizada: 'query q ($clienteId: Long!) { content: methodName# (clienteId: $clienteId) { fields# }  }',
        methodName: 'obtenerPolizasByClienteId',
        fields: type.getFields(),
    }),

    desactivarById: new GQLQuerySpec({
        queryType: queryType.MUTATION_NO_RESULT,
        methodName: 'desactivar' + typeName + 'ById',
        fields: type.getFields(),
    }),

    activarById: new GQLQuerySpec({
        queryType: queryType.MUTATION_NO_RESULT,
        methodName: 'activar' + typeName + 'ById',
        fields: type.getFields(),
    }),

    actualizarBeneficiarioList: new GQLQuerySpec({
        queryType: queryType.PERSONALIZADA,
        queryPersonalizada: 'mutation m ($instancia: PolizaInput) {content: methodName# (instancia: $instancia) { fields# } }',
        methodName: 'actualizarBeneficiariosBy' + typeName + 'Id',
        fields: type.getFields(),
    }),

    actualizarUltimaPantalla: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'actualizarUltimaPantalla',
        fields: type.getFields(),
    }),

    calcularCoeficientePorCodigoPostal: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'calcularCoeficientePorCodigoPostal',
        fields: type.getFields(),
    }),

    calcularCoeficienteFraccionamiento: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'calcularCoeficienteFraccionamiento',
        fields: type.getFields(),
    }),

    calcularCoeficienteProfesionRiesgo: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'calcularCoeficienteProfesionRiesgo',
        fields: type.getFields(),
    }),

    calcularAgravamientoPorElemento: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'calcularAgravamientoPorElemento',
        fields: type.getFields(),
    }),

    aplicarDescuentoPoliza: new GQLQuerySpec({
        queryType: queryType.MUTATION,
        methodName: 'aplicarDescuentoPoliza',
        fields: type.getFields(),
    }),

    calcularPC: new GQLQuerySpec({
        queryType: queryType.PERSONALIZADA,
        queryPersonalizada: 'query q ($primaBruta: Float!, $ggi: Float!) { content: calcularPC(primaBruta: $primaBruta, ggi: $ggi) }',
    }),

    calcularPCIPA: new GQLQuerySpec({
        queryType: queryType.PERSONALIZADA,
        queryPersonalizada: 'query q ($primaBruta: Float!, $ggi: Float!, $ratio: Float!, $factorCP: Float!) { content: calcularPCIPA(primaBruta: $primaBruta, ggi: $ggi, ratio: $ratio, factorCP: $factorCP) }',
    }),

    desactivarPolizasByClienteId: new GQLQuerySpec({
        queryType: queryType.MUTATION_NO_RESULT,
        methodName: 'desactivarPolizasByClienteId',
        queryPersonalizada: 'mutation m ($clienteId: Long!) { desactivarPolizasByClienteId(clienteId: $clienteId) }',
    }),
}
