import { Type } from '@angular/core';

export class GQLQuerySpec {
    queryType!: queryType;
    methodName?: string;
    fields?: string;
    queryPersonalizada?: string;

    public constructor(querySpec?: GQLQuerySpec) {
        Object.assign(this, querySpec);
    }
}

export enum queryType {
    QUERY,
    QUERY_PAGINADA,
    MUTATION,
    MUTATION_NO_RESULT,
    PERSONALIZADA,
}

// generacion de 'content:'
export const getFieldsFromInstance = (instance: any) => {
    const fields: string[] = ['id'];
    if (instance) {
        // console.log(`[${instance.constructor.name}]`);
        for (const [key, value] of Object.entries(instance)) {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    fields.push(key + ' { ' + getFieldsFromInstance(value[0]) + ' }');
                }
            } else if (isEntity(key, value)) {
                fields.push(key + ' { ' + getFieldsFromInstance(value) + ' }');
            } else if (!key.startsWith('_')) {
                fields.push(key);
            }
            // console.log(`  ${key}: ${typeof value}`);
        }
        // console.log(`  ${retVal}`);
    }
    return fields.join(' ');
};

export const getGQLQueryString = (querySpec: GQLQuerySpec, variables: any, paramType?: string): string => {
    if (!querySpec) {
        console.error('No existe la query');
    }

    const plantillaQuery = 'query q queryParams# { content: methodName# methodParams# { fields# } }';
    const plantillaQueryPaginada = 'query q queryParams# { content: methodName# methodParams# { totalElements, content { fields# } } }';
    const plantillaMutation = 'mutation m queryParams# { content: methodName# methodParams# { fields# } }';
    const plantillaMutationNoResult = 'mutation m queryParams# { content: methodName# methodParams# }';

    const placeholderQueryParams = 'queryParams#';
    const placeholderMethodNames = 'methodName#';
    const placeholderMethodParams = 'methodParams#';
    const placeholderFields = 'fields#';

    let query: string = '';
    if (querySpec.queryType === queryType.QUERY) {
        query = plantillaQuery.toString();
    } else if (querySpec.queryType === queryType.QUERY_PAGINADA) {
        query = plantillaQueryPaginada.toString();
    } else if (querySpec.queryType === queryType.MUTATION) {
        query = plantillaMutation.toString();
    } else if (querySpec.queryType === queryType.MUTATION_NO_RESULT) {
        query = plantillaMutationNoResult.toString();
    } else if (querySpec.queryType === queryType.PERSONALIZADA && querySpec.queryPersonalizada) {
        query = querySpec.queryPersonalizada;
    }

    let queryParams = '';
    let methodParams = '';
    if (variables) {
        for (const [key, value] of Object.entries(variables)) {
            
            queryParams += queryParams.length === 0 ? '(' : ',';
            methodParams += methodParams.length === 0 ? '(' : ',';

            // ($id: Long)
            queryParams += `$${key}: ${getParamTypeName(value, paramType)}`;

            // (id: $id)
            methodParams += `${key}: $${key}`;
        }
        queryParams += queryParams.length === 0 ? '' : ')';
        methodParams += methodParams.length === 0 ? '' : ')';
    }

    query = replacePlaceholder(query, placeholderQueryParams, queryParams);
    query = replacePlaceholder(query, placeholderMethodNames, querySpec.methodName!);
    query = replacePlaceholder(query, placeholderMethodParams, methodParams!);
    query = replacePlaceholder(query, placeholderFields, querySpec.fields!);

    return query;
};

const replacePlaceholder = (query: string, placeholder: string, value: string) => {
    const posicion = query.indexOf(placeholder);
    if (posicion > -1) {
        return [query.slice(0, posicion), value ? value : '', query.slice(posicion + placeholder.length)].join('');
    } else {
        return query;
    }
};

const basicFields = new Map<string, string>([['Date', 'Date']]);

const getParamTypeName = (value: any, paramType?: string) => {
    let typeName: string = '';
    if (paramType) {
        typeName = paramType + 'Input';
    } else {
        switch (typeof value) {
            case 'object':
                if (basicFields.has(value.constructor.name)) {
                    typeName = basicFields.get(value.constructor.name)!;
                } else if (value.hasOwnProperty('_className')){
                    typeName = value._className + 'Input';
                } else {
                    typeName = value.constructor.name + 'Input';
                }
                break;
            case 'number':
                if (value.toString().includes('.')) {
                    typeName = 'Double';
                } else {
                    typeName = 'Long';
                }
                break;
            case 'string':
                typeName = 'String';
                break;
            default:
                throw ('Tipo de parámetro desconocido: {' + typeof value + '}');
        }
    }
    return typeName;
};

const isEntity = (key: string, value: unknown) => {
    return typeof value === 'object' && key !== 'id' && value !== null && !basicFields.has(value.constructor.name);
};