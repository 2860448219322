import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalContactoComponent } from 'src/app/app.components/modales/modal-contacto/modal-contacto.component';
import { AppEvents } from 'src/app/app.config/app-events';
import { ConstantesApp } from 'src/app/app.config/constantes-app';
import { Colectivo } from 'src/app/app.domain.models/colectivo';
import { EventBrokerService } from 'src/app/core.services/event-broker.service';
import { environment } from 'src/environments/environment';

export const FORMATO_DATE_MES = {
    display: {
        dateInput: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-header-colectivos',
    templateUrl: './header-colectivos.component.html',
    providers: [{ provide: MAT_DATE_FORMATS, useValue: FORMATO_DATE_MES }],
})
export class HeaderColectivosComponent {
    readonly CONSTANTES_APP = ConstantesApp;
    @Input() menuAbierto: boolean = true;
    @Output() reportarEstadoMenu = new EventEmitter<boolean>();

    colectivo: Colectivo;
    colectivoLogoUrl = '';
    textoAlternativoLogo = '';

    constructor(
        private router: Router, 
        private translateService: TranslateService,
        private eventBrokerService: EventBrokerService,
        public dialog: MatDialog,
    ) { 
            
        this.colectivo = new Colectivo();
        this.eventBrokerService.subscribeEvent(AppEvents.actualizarColectivo).subscribe((payload: Colectivo) => this.actualizarColectivo(payload));
    }

    // Controla el icono del menu y notifica al padre
    toggleSideMenu() {
        // cambia el icono a traves del estado
        this.menuAbierto = !this.menuAbierto;
        //notifica al padre
        this.reportarEstadoMenu.emit(this.menuAbierto);
    }

    logout() {

        // const actionTitle = 'login.cerrarSesion';
        // const modalData = new DialogData(actionTitle, this, (context: AppSiteHeaderComponent) => {
        //     context.loginService.logout().then(() => {
        //         context.router.navigate([ConstantesApp.PATH_USER_LOGIN]);
        //     });
        // });
        // this.eventBrokerService.publishEvent(CoreEvents.modalConfirm, modalData);
        this.eventBrokerService.publishEvent("logout");
    }

    login() {
        this.router.navigateByUrl(ConstantesApp.PATH_USER_LOGIN);
    }    

    public actualizarColectivo(colectivo: Colectivo) {
        if(colectivo){
            this.colectivo = colectivo;
            this.colectivoLogoUrl = environment.staticContentPath + colectivo.archivoLogo.url;
            this.textoAlternativoLogo = colectivo.url + " " + this.translateService.instant('header.texto.alt.logo');
        }
    }

    contacto() {
        const dialogRefContactoUsuario = this.dialog.open(
            ModalContactoComponent,
            { panelClass: 'custom-modal-contacto', }
        );
        dialogRefContactoUsuario.afterClosed().subscribe((result) => { });
    }

}
