import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EventBrokerService {
    private allEvents: { [id: string]: EventEmitter<any>; } = {};

    constructor() {
    }

    registerEvent(eventName: string) {
        if (!this.allEvents[eventName]) {
            this.allEvents[eventName] = new EventEmitter();
        }
    }

    publishEvent(eventName: string, payload: any = {}) {
        if (!this.allEvents[eventName]) {
            console.error(`${eventName} event not registered...`);
            return;
        }
        this.allEvents[eventName].next(payload);
    }

    subscribeEvent(eventName: string) {
        if (!this.allEvents[eventName]) {
            console.error(`${eventName} event not registered...`);
        }
        return this.allEvents[eventName];
    }

    clearEvents() {
        this.allEvents = {};
    }
}