import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { camposFechaCorta, camposFechaHora } from '../../app.config/app-campos-fecha';
import { Utilidades } from '../utilidades';

export const dateFormat = {
    //2012-01-01T00:00:00.000+01:00
    dotNet: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}$/,
    isoDateFormat: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/,
    isomsDateFormat: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}\+\d{4}$/,
    gmtDateFormat: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/,
    gmtDateFormatSS: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/,
    ddmmyyyyDateFormat: /^\d{2}\/\d{2}\/\d{4}$/,
    ddmmyyyyHHmmDateFormat: /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/,
    ddmmyyyyHHmmssDateFormat: /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/,
};

export class DateInterceptorUtils {
    static isDateString(value: any): boolean {
        return (
            typeof value === 'string' &&
            (dateFormat.gmtDateFormat.test(value) ||
                dateFormat.dotNet.test(value) ||
                dateFormat.isoDateFormat.test(value) ||
                dateFormat.isomsDateFormat.test(value) ||
                dateFormat.ddmmyyyyDateFormat.test(value) ||
                dateFormat.ddmmyyyyHHmmDateFormat.test(value) ||
                dateFormat.ddmmyyyyHHmmssDateFormat.test(value) ||
                dateFormat.gmtDateFormatSS.test(value))
        );
    }

    static isNonISODateString(value: any): boolean {
        return (
            typeof value === 'string' &&
            (dateFormat.gmtDateFormat.test(value) ||
                dateFormat.isomsDateFormat.test(value) ||
                dateFormat.ddmmyyyyDateFormat.test(value) ||
                dateFormat.ddmmyyyyHHmmDateFormat.test(value) ||
                dateFormat.ddmmyyyyHHmmssDateFormat.test(value) ||
                dateFormat.gmtDateFormatSS.test(value))
        );
    }

    static convertToTs(body: any, datePipe: DatePipe, translateService: TranslateService) {
        if (body === null || body === undefined) {
            return body;
        }
        if (typeof body !== 'object') {
            return body;
        }
        for (const key of Object.keys(body)) {
            const value = body[key];
            if (typeof value === 'object') {
                this.convertToTs(value, datePipe, translateService);
            } else if (
                typeof value === 'string' &&
                (camposFechaCorta.includes(key) || camposFechaHora.includes(key)) &&
                this.isDateString(value)
            ) {
                body[key] = moment(value).toDate();
            }
        }
    }

    static convertToISO(body: any, datePipe: DatePipe, translateService: TranslateService) {
        if (body && typeof body === 'object') {

            // recorro sus miembros
            for (const key of Object.keys(body)) {
                const value = body[key];

                // si es una cadena, la key es un campo conocido de fecha, y la fecha no es ISO
                if (typeof value === 'string'
                    && (camposFechaCorta.includes(key) || camposFechaHora.includes(key))) {
                    if (DateInterceptorUtils.isNonISODateString(value)) {

                        // la traducimos y reemplazamos su valor en el objeto
                        const newDate = new Date(Utilidades.getDate(value));
                        body[key] =
                            datePipe.transform(
                                newDate,
                                translateService.instant('formato.fecha.iso')
                            ) + 'Z';
                    }

                } else {
                    DateInterceptorUtils.convertToISO(value, datePipe, translateService);
                }
            }
        }
    }
}
