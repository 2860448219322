<div class="container-modal">
    <div class="header-modal">
        <h1 class="text-color-primary"><strong>{{ 'modal-contacto.header' | translate }}</strong></h1>
        <button mat-icon-button (click)="cerrarModalGeneric()" class="btn-icon-cerrar">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <hr>
    <div class="body-modal">
        <form class="formularioContacto" [formGroup]="formContacto">
            <div class="fila">
                <input id="nombre" name="nombre" type="text" placeholder="{{'modal-contacto.nombre-placeholder' | translate}}" formControlName="nombre"
                            class="input-form-control">
                <div *ngIf="formContacto.get('nombre')?.touched && getFormContacto['nombre'].errors"
                    class="content-validacion-form">
                    <div>{{getError(getFormContacto['nombre'], formularioContacto)}}</div>
                </div>
            </div>
            <div class="fila">
                <input id="email" name="email" type="email" placeholder="{{'modal-contacto.email' | translate}}" formControlName="email"
                            class="input-form-control">
                <div *ngIf="formContacto.get('email')?.touched && getFormContacto['email'].errors"
                    class="content-validacion-form">
                    <div>{{getError(getFormContacto['email'], formularioContacto)}}</div>
                </div>
            </div>
            <div class="fila">
                <input id="telefono" name="telefono" type="telefono" placeholder="{{'modal-contacto.telefono' | translate}}" formControlName="telefono"
                            class="input-form-control">
                <div *ngIf="formContacto.get('telefono')?.touched && getFormContacto['telefono'].errors"
                    class="content-validacion-form">
                    <div>{{getError(getFormContacto['telefono'], formularioContacto)}}</div>
                </div>
            </div>
            <div class="fila">
                <textarea name="mensaje" id="mensaje" placeholder="{{'modal-contacto.mensaje' | translate}}" formControlName="mensaje"
                    class="input-form-control text-area"></textarea>
                <div *ngIf="formContacto.get('mensaje')?.touched && getFormContacto['mensaje'].errors"
                    class="content-validacion-form-textarea">
                    <div>{{getError(getFormContacto['mensaje'], formularioContacto)}}</div>
                </div>
            </div>
            <div class="enviar-formulario">
                <app-generic-field-button-next-back [direccion]="'next'" [texto]="'Enviar'"
                        [estilo]="'simple'" (eventoClick)="enviarMensaje()">
                </app-generic-field-button-next-back>
            </div>
        </form>
    </div>
</div>