export class DialogData {
    titulo: string;
    callback?: (callbackParam: any, contexto: any) => any;
    callbackCancelar?: (callbackParam: any, contexto: any) => any;
    callbackParams?: any;
    callbackCancelarParams?: any;
    contexto: any;

    constructor(
        titulo: string,
        contexto: any,
        callback?: (callbackParam: any, contexto: any) => any,
        callbackParams?: any,
        callbackCancelar?: (callbackParam: any, contexto: any) => any,
        callbackCancelarParams?: any,
    ) {
        this.titulo = titulo;
        this.callback = callback!;
        this.callbackParams = callbackParams;
        this.callbackCancelar = callbackCancelar!;
        this.callbackCancelarParams = callbackCancelarParams;
        this.contexto = contexto;
    }
}
