import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantesApp } from 'src/app/app.config/constantes-app';
import { SesionService } from 'src/app/core.services/sesion.service';

@Component({
    selector: 'app-forbidden',
    templateUrl: './forbidden.component.html',
})
export class ForbiddenComponent {

    constructor(
        private router: Router, 
        protected sesion: SesionService) {
        // borrar sesion al menos usuarioLogado
        sesion.clearSession();

        // redirigir al login pasado X tiempo
        // setTimeout(() => {
        //     this.login();
        // }, 10000);
    }

    public login() {
        this.router.navigateByUrl(ConstantesApp.PATH_USER_LOGIN)
    }

}
