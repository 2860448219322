import { environment } from 'src/environments/environment';

export const ConstantesCore = {

    /** Paths */
    CONTEXT_ROOT: '/',

    ENDPOINT_GRAPHQL: environment.apiUrl + '/graphql',
    ENDPOINT_LOGIN: environment.apiUrl + '/security/authenticate',
    ENDPOINT_GET_USER_TOKEN: environment.apiUrl + '/security/getUserToken',
    ENDPOINT_ESTABLECER_PASSWORD: environment.apiUrl + '/security/passwordUpWithTokenToReset',

    /** Maxima resolucion para colapsar Sidemenu */
    SIDEBAR_CLOSE_MAX_RESOLUTION: 768,

    /** Permisos de usuario */
    PERMISO_CONSULTA: 'C',
    PERMISO_MODIFICACION: 'M',
    PERMISO_NAVEGACION: 'NAVEGACION',


    /** Constantes de variables de sesión */
    SESION_USUARIO_LOGADO: 'usuarioLogado',
    SESION_TOKEN: 'token',
    SESION_LOGIN: 'login',
    SESION_PERMISO_LIST: 'permisoList',
    SESION_CLIENTE_NUEVO: 'clienteNuevo',

    /** Errores HTTP */
    TOKEN_CADUCADO: 401,
    NO_AUTORIZADO: 403,

    /** Nombres de cabecera */
    TOKEN_RESPONSE_HEADER: 'X-Refresh-Token',
    AUTHORIZATION_HEADER: 'Authorization',

    /** Tipos de usuario */
    DEFAULT_FILE_IMAGE: '/assets/images/files/nopreview.svg',

    TOKEN_LISTA_PROCESADA: ';',

    /** Constantes datatable generico */
    /**Tipo checkbox  - datatable generico*/
    CHECKBOX: 'CHECKBOX',
    CHECKBOX_SELECTOR_FILAS: 'CHECKBOX_SELECTOR_FILAS',
    EXPORTAR_EXCEL: '/assets/images/files/excel.svg',
    XLSX_EXTENSION: '/assets/images/files/excel.svg',
    PDF_EXTENSION: '/assets/images/files/pdf.svg',
    IMG_EXTENSION: '/assets/images/files/img.svg',
    DOC_EXTENSION: '/assets/images/files/word.svg',

    // TIPOS COLUMNA DATATABLE
    TIPO_COLUMNA_ADJUNTO: 'ADJUNTO',
    TIPO_COLUMNA_ENVIADO_RECIBIDO: 'ENVIADO_RECIBIDO',

    // Color por defecto para nuevo color
    DEFAULT_COLOR_PICKER: '#ffffff',

    // Constantes para cadenas de caracteres
    GUION: '-',
    GUION_ESPACIO: ' - ',
    ESPACIO: ' ',
    EXPORT_TYPE_PDF: 'PDF',
    EXPORT_TYPE_EXCEL: 'EXCEL',

    // Formatos de fecha
    DATE_FORMAT_YYYY_MM_DD: 'yyyy-MM-dd',
    DATE_FORMAT_DD_MM_YYYY: 'dd-MM-yyyy',
    DATE_FORMAT_DD_MM_YYYY_slash: 'dd/MM/yyyy',
    FORMATO_FECHA_CORTA: 'dd/MM/yyyy',

    DATE_SEPARATOR: '/',

    FECHA_MAX_FILTER: 'Dec 31 2099',
    FECHA_MIN_FILTER: 'Jan 01 1900',

    /** Constantes de idiomas */
    UNICODE_ES_ES: 'es-ES',
    UNICODE_ES: 'es',

    /** Constantes de cifrado */
    SHA256_SALT: 'LgLp5LZqgS',


    /** Constantes de Estado - datatable generico */
    ESTADO: 'estado',
    ACTIVO: 'activo',
    ES_INACTIVO: 'Inactivo',
    ES_ACTIVO: 'Activo',
    ACUMULABLE: 'acumulable',
    ES_ACUMULABLE: 'Acumulable',
    ES_NO_ACUMULABLE: 'No acumulable',
    BOOLEAN: 'boolean',
    EMITIDA: 'Emitida',
    GENERADA: 'Generada',
    ALTERADO: 'alterado',

    /** Constantes para clases css  - datatable generico*/
    ICON_ACTIVO_TRUE: 'icon_activo_true',
    ICON_ACTIVO_FALSE: 'icon_activo_false',
    ICON_ACUMULABLE_TRUE: 'icon_acumulable_true',
    ICON_ACUMULABLE_FALSE: 'icon_acumulable_false',
    ICON_BOOLEAN_TRUE: 'icon_boolean_true',
    ICON_BOOLEAN_FALSE: 'icon_boolean_false',

    // DIRECCIÓN ORDEN PAGINACIÓN
    DIRECCION_ORDEN_ASCENDENTE: 'asc',

    // Paginación máxima
    PAGINATOR_MAX_PAGE_SIZE: 2147483647,

    MODAL_IRRECUPERABLE_PALABRA_CLAVE_REPLACE: '${palabraClave}',

    PREFIJO_FUNCION_MAESTROS: 'getMaestros',

    SPLIT_PROPIEDADES_CONCATENADAS_FILTRO: ',',
    SPLIT_PROPIEDADES_CONCATENADAS_CLIENTE: '|',

    FILTRO_CLIENTE_ID: 0,
    FILTRO_CLIENTE_LIKE: 1,

    //Rutas de imagenes
    LOGO_ASTRA: '/assets/logo-ASTRA.png', 
    LOGO_AKIBU: '/assets/emails/logo-AKIBU.png', 
    LOGO_AXA: '/assets/emails/logo-AXA.png', 
    IMAGEN_FAMILIA: '../../../../assets/images/familia.PNG',
    ICONO_ARCHIVO: '../../../../assets/images/icons/archivo.png',
    ICONO_BENEFICIARIOS: '../../../../assets/images/icons/multiples-usuarios.png',
    ICONO_BENEFICIARIOS_GRIS: '../../../../assets/images/icons/multiples-usuarios-gris.png',
    ICONO_BANCO: '../../../../assets/images/icons/banco.png',
    ICONO_BANCO_GRIS: '../../../../assets/images/icons/banco-gris.png',

    /** Expresiones regulares */
    REGEX_EMAIL: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(\\.[a-zA-Z]{2,4})$',
    REGEX_EMAIL_OPCIONAL: '(?:^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(\\.[a-zA-Z]{2,4})$)|^$',
    REGEX_TELEFONO: /^(?:[\(](?:\+{0,1})\d{1,4}[\)] ){0,1}([1-9]{1}\d{2})(( \d{2}){3}|( \d{3}){2}|\d{6})$/,
    CADENA_TEXTO: /^(?=[\s\S]{1,255}$)[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]*$/,
    CADENA_TEXTO_NUMERO_LIBRE: /^(?=[\s\S]{1,255}$)[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s,0-9]*$/,
    NUMEROS_ENTEROS_POSITIVOS: /^[0-9]\d*$/,
    NUMEROS_DECIMALES_POSITIVOS: /^(?:\d+,\d*|\d+)$/,
    NUMEROS_ENTEROS_PORCENTAJE: /^([1-9]|[1-9][0-9]|100)$/,
    NUMEROS_ENTEROS_PORCENTAJE_CON_CERO: /^0$|^(?:[1-9]|[1-9][0-9]|100)$/,
    REGEX_TENSION: /^(?!0([.,]0+)?$)(?=\d{1,4}(?:[.,]\d{0,3})?$)\d+(?:[.,]\d*)?$/,
    REGEX_NUMERO_TARJETA: /^(?:\d{12,16}|\d{4}\s\d{4}\s\d{4}(?:\s\d{3})?|\d{4}\s\d{4}\s\d{4}\s\d{4})$/,
    REGEX_FECHA_EXPIRACION: /^(0[1-9]|1[0-2])\/(2[4-9]|[3-9]\d)$/,
    REGEX_CVC: /^\d{3,4}$/,

    /* Constantes para limitar campos numéricos entre digitos */
    REGEX_TRES_DIGITOS: /^\d{3}$/,
    REGEX_UNO_TRES_DIGITOS: /^\d{1,3}$/,
    REGEX_DOS_TRES_DIGITOS: /^\d{2,3}$/,
    REGEX_DOS_CUATRO_DIGITOS: /^\d{2,4}$/,
    REGEX_VALOR_MAYOR_CERO: /^[1-9]\d*(\.\d+)?$/,



    // DNI_NIE_FORMATO: /^[XYZ]?\d{7,8}[A-Z]$/,
    DNI_NIE_FORMATO: '[A-HJ-NP-SUVWXYa-hj-np-suvwxy][0-9]{7}[0-9A-Ja-j]|\\d{8}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]|[Xx]\\d{7}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]|[Xx]\\d{8}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]|[YZyz]\\d{0,7}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]',
    REGEX_IBAN: '^[A-Za-z]{2}[0-9]{2}(?: ?[0-9]{4}){5}$',
    PLANTILLA_LIST_INICIO: '$${itemList.inicio}',
    PLANTILLA_LIST_FIN: '$${itemList.fin}',

    /** Validaciones de contraseña desglosadas */
    PASSWORD_DIGIT: /\d/,
    PASSWORD_LOWER_CASE: /[a-z]/,
    PASSWORD_UPPER_CASE: /[A-Z]/,
    PASSWORD_SPECIAL_CHAR: /[^a-zA-Z0-9]/,
    PASSWORD_MIN_LENGTH: /^(?=.*\d).{8,}$/,

    EOF: 'Fin del fichero, insertar por encima'
}