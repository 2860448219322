<div class="modal-generico">
    <div class="contenedor-modal-confirmacion">
        <mat-icon>info</mat-icon>
       <h3>{{data.formTitle | translate}}</h3>
    </div>
    <div mat-dialog-actions>
        <button class="btn-linea btn-secundario btn-margin" (click)="clickCancel()"><span>{{ 'generic-form.label.no' | translate}}</span></button>
        <button class="btn-fill" [mat-dialog-close]="data.entity" cdkFocusInitial (click)="clickOk()">{{'generic-form.label.si' | translate}}</button>
    </div>
</div>
