import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ComponentPortal, ToastrService } from 'ngx-toastr';
import { ConstantesApp } from 'src/app/app.config/constantes-app';
import { EmailTemplate } from 'src/app/app.domain.models/email-template';
import { EnvioEmail } from 'src/app/app.domain.models/envio-email';
import { EmailTemplateApiService } from 'src/app/app.domain.services/email-template.api.service';
import { EnvioEmailApiService } from 'src/app/app.domain.services/envio-email.api.service';
import { Utilidades } from 'src/app/core.utilidades/utilidades';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modal-contacto',
    templateUrl: './modal-contacto.component.html',
    styleUrls: ['./modal-contacto.component.scss']
})
export class ModalContactoComponent {
    portal!: ComponentPortal<any>;
    mostrarBtnFooter: boolean = true;

    formContacto!: FormGroup;
    formularioContacto: string = "contacto";

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translateService: TranslateService,
        private toastr: ToastrService,
        private emailTemplateService: EmailTemplateApiService,
        private envioEmailService: EnvioEmailApiService,
    ) { }

    ngOnInit() {
        // this.mostrarBtnFooter = this.data.mostrarBtnFooter;

        this.formContacto = new FormGroup({
            nombre: new FormControl('', [Validators.required, Validators.pattern(ConstantesApp.CADENA_TEXTO), Validators.maxLength(60),]),
            email: new FormControl('', [Validators.required, Validators.pattern(ConstantesApp.REGEX_EMAIL_OPCIONAL)]),
            telefono: new FormControl('', [Validators.required, Validators.minLength(9), Validators.pattern(ConstantesApp.REGEX_TELEFONO)]),
            mensaje: new FormControl('', [Validators.required,  Validators.minLength(10), Validators.maxLength(255), Validators.pattern(ConstantesApp.CADENA_TEXTO)]),
        });
    }

    get getFormContacto(): { [key: string]: AbstractControl } { return this.formContacto.controls; }
    getError(data: any, formulario: string) {
        let transMessageError = Utilidades.obtenerErrorFormControl(data, formulario);
        if (transMessageError != "") return this.translateService.instant(transMessageError);
        return "";
    }

    enviarMensaje() {
        if (this.formContacto.valid) {
            let nombre = this.formContacto.controls["nombre"].value;
            let email = this.formContacto.controls["email"].value;
            let telefono = this.formContacto.controls["telefono"].value;
            let mensaje = this.formContacto.controls["mensaje"].value;
            
            this.enviarMailContacto(nombre, email, telefono, mensaje);
        }  else {
            this.formContacto.markAllAsTouched();
            this.toastr.error(this.translateService.instant('generic-form.error.general'));
            return;
        }
    }

    private enviarMailContacto(nombreContacto: string, emailContacto: string, telefonoContacto: string, mensajeContacto: string): Promise<void>  {
        return new Promise<void>((resolve, reject) => {
            this.emailTemplateService.obtenerById(ConstantesApp.PLANTILLA_EMAIL_CONTACTO_USUARIO_ID).then((template: EmailTemplate) => {
                if (template) {
                    let variables: object = {
                        staticContentPath: environment.staticContentPath,
                        nombre: nombreContacto,
                        email: emailContacto,
                        telefono: telefonoContacto,
                        mensaje: mensajeContacto,

                    };
                    const asunto = Utilidades.rellenarPlantilla(template.asunto, variables);
                    const cuerpo = Utilidades.rellenarPlantilla(template.cuerpo, variables);

                    const envioEmail = new EnvioEmail();
                    envioEmail.destinatario = ConstantesApp.EMAIL_AGENTE_CONTACTO,
                    envioEmail.asunto = asunto;
                    envioEmail.cuerpo = cuerpo;
                    envioEmail.fecha = new Date();
                    envioEmail.enviado = 0;
                    envioEmail.adjuntos = [];

                    // Hacemos el envío del correo
                    this.envioEmailService.crear(envioEmail)
                        .then(() => {
                            resolve();
                            this.toastr.success(this.translateService.instant('toast.mensaje.enviado.exito'));
                        })
                        .catch((error: any) => {
                            reject(error);
                        });
                    this.cerrarModalGeneric();

                }
            })
        })
    }

    cerrarModalGeneric(): void {
        this.dialogRef.close();
    }
}
