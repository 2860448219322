import { ITheme } from "../app.domain.models/itheme.interface";

export const THEMES: ITheme = {
    default: {
        primaryColor: '#504D9B',
    },
    individual: {
        primaryColor: '#504D9B',
    }
};
