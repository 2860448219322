<div class="navbar-top">
    <div class="container">
        <div class="logo div-header">
            <img id="logo-colectivo" src="assets/images/logo-ASTRA.png" alt="AXA Seguros Logo">
        </div>

        <div class="div-header">
            <a href="tel:{{ agente.telefono }}" class="enlace-btn-telefono">
                <span class="material-symbols-outlined">phone_in_talk</span>
            </a>
            <p id="tlf"><a href="tel:{{ agente.telefono }}" class="enlace-btn-telefono">{{ agente.telefono }}</a></p>
            <button class="btn-icon-blue logout" (click)="logout()">
                <mat-icon aria-hidden="false" aria-label="Logout" fontIcon="logout"
                    class="navbar-acciones-icon"></mat-icon>
                    <span> {{ 'msg.general.logout' | translate }} </span>
            </button>
        </div>
    </div>
</div>