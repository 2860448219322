import { NamedType } from '../core.models/named-type';
import { getFieldsFromInstance } from '../core.utilidades/graphql-utils';
import { TipoSexo } from './tipo-sexo';

export class Cliente implements NamedType{
    _className: string = 'Cliente';

    id?: number;

    nombre: string;
    apellido1: string;
    apellido2: string;
    identificacion: string;
    tipoSexo?: TipoSexo;

    profesion: string;
    fechaNacimiento!: Date;

    email: string;
    telefono: string;
    direccion: string;
    provincia: string;
    ciudad: string;
    codigoPostal: string;

    credencialId?: number;
    activo: boolean;

    constructor() {
        this.nombre = '';
        this.apellido1 = '';
        this.apellido2 = '';
        this.identificacion = '';
        this.profesion = '';
        this.email = '';
        this.telefono = '';
        this.direccion = '';
        this.provincia = '';
        this.ciudad = '';
        this.codigoPostal = '';
        this.activo = true;
        this.credencialId = undefined;
    }
    
    public static getFields = () => {
        const instancia = new Cliente();
        instancia.fechaNacimiento = new Date();
        instancia.tipoSexo = new TipoSexo();
        return getFieldsFromInstance(instancia);
    };
}
