import { environment } from 'src/environments/environment';
import { ConstantesCore } from '../core.utilidades/constantes-core';

const ParentPaths = {
    PATH_COLECTIVOS: '/colectivos',
    PATH_INDIVIDUAL: '/individual',
    PATH_AREA_CLIENTE: '/area-cliente',
    PATH_BACK_OFFICE: '/back-office',
}
const ConstantesPersonalizadas = {

    /** Paths */
    PATH_FORBIDDEN: '/forbidden',

    PATH_USER_LOGIN: '/user/login',
    PATH_USER_RESET_PASSWORD: '/user/reset-password',
    ROUTING_USER_NUEVO_PASSWORD: '/user/establecer-password',

    STATIC_CONTENT_PATH: environment.staticContentPath,
    STATIC_CONTENT_PATH_RELATIVO_LOGO_COLECTIVOS: 'assets/colectivos/',
    STATIC_CONTENT_PATH_RELATIVO_POLIZAS_COLECTIVOS: 'polizas/',
    STATIC_CONTENT_PATH_RELATIVO_LOGO_INDIVIDUAL: environment.staticContentPath + '/assets/logo-akibu.png',
    STATIC_CONTENT_PATH_RELATIVO_LOGO_INDIVIDUAL_AXA: environment.staticContentPath + '/assets/logo-axa.png',

    /** AREA-CLIENTE */
    PATH_AREA_CLIENTE_INICIO: ParentPaths.PATH_AREA_CLIENTE + '/inicio',
    PATH_AREA_CLIENTE_MIS_DATOS: ParentPaths.PATH_AREA_CLIENTE + '/mis-datos',
    PATH_AREA_CLIENTE_MIS_POLIZAS: ParentPaths.PATH_AREA_CLIENTE + '/mis-polizas',

    /** PRESUPUESTO COLECTIVOS */
    PATH_COLECTIVOS_1_CALCULAR_SEGURO: ParentPaths.PATH_COLECTIVOS,
    PATH_COLECTIVOS_2_VER_PRECIO: ParentPaths.PATH_COLECTIVOS + '/ver-precio',
    PATH_COLECTIVOS_3_MEJOR_PRECIO: ParentPaths.PATH_COLECTIVOS + '/mejor-precio',
    PATH_COLECTIVOS_4_PREGUNTAS_COLECTIVOS: ParentPaths.PATH_COLECTIVOS + '/preguntas-colectivo',
    PATH_COLECTIVOS_5_CONTRATAR: ParentPaths.PATH_COLECTIVOS + '/contratar',
    PATH_COLECTIVOS_6_FIRMA: ParentPaths.PATH_COLECTIVOS + '/firma',
    PATH_COLECTIVOS_7_SOLICITUD_CURSO: ParentPaths.PATH_COLECTIVOS + '/solicitud-en-curso',
    
    /** PRESUPUESTO INDIVIDUAL */
    PATH_INDIVIDUAL_1_CALCULAR_SEGURO: ParentPaths.PATH_INDIVIDUAL,
    PATH_INDIVIDUAL_2_VER_PRECIO: ParentPaths.PATH_INDIVIDUAL + '/ver-precio',
    PATH_INDIVIDUAL_3_CUESTIONARIO_SALUD: ParentPaths.PATH_INDIVIDUAL + '/cuestionario-salud',
    PATH_INDIVIDUAL_4_MEJOR_PRECIO: ParentPaths.PATH_INDIVIDUAL + '/mejor-precio',
    PATH_INDIVIDUAL_5_CONTRATAR: ParentPaths.PATH_INDIVIDUAL + '/contratar',
    PATH_INDIVIDUAL_6_FIRMA: ParentPaths.PATH_INDIVIDUAL + '/firma',
    PATH_INDIVIDUAL_7_PAGO_TARJETA: ParentPaths.PATH_INDIVIDUAL + '/pago-tarjeta',
    PATH_INDIVIDUAL_8_PROCESAR_PAGO: ParentPaths.PATH_INDIVIDUAL + '/procesar-pago',
    PATH_INDIVIDUAL_9_FIRMA_LOGALTY: ParentPaths.PATH_INDIVIDUAL + '/firma-logalty',
    PATH_INDIVIDUAL_10_SOLICITUD_CURSO: ParentPaths.PATH_INDIVIDUAL + '/solicitud-en-curso',
    PATH_INDIVIDUAL_11_ARTICULO: ParentPaths.PATH_INDIVIDUAL + '/articulo',

    /** BACK OFFICE */
    PATH_BACK_OFFICE_INICIO: ParentPaths.PATH_BACK_OFFICE + '/inicio',
    PATH_BACK_OFFICE_POLIZAS: ParentPaths.PATH_BACK_OFFICE + '/polizas',
    PATH_BACK_OFFICE_CLIENTES: ParentPaths.PATH_BACK_OFFICE + '/clientes',
    PATH_BACK_OFFICE_CARRITO_ABANDONADO: ParentPaths.PATH_BACK_OFFICE + '/carrito-abandonado',
    PATH_BACK_OFFICE_AGENTES: ParentPaths.PATH_BACK_OFFICE + '/agentes',
    PATH_BACK_OFFICE_COLECTIVOS: ParentPaths.PATH_BACK_OFFICE + '/colectivos',
    PATH_BACK_OFFICE_TIPO_POLIZA: ParentPaths.PATH_BACK_OFFICE + '/tipo-poliza',
    PATH_BACK_OFFICE_PAQUETES_POLIZAS: ParentPaths.PATH_BACK_OFFICE + '/paquetes-polizas',
    PATH_BACK_OFFICE_CONFIGURACION: ParentPaths.PATH_BACK_OFFICE + '/configuracion',
    PATH_BACK_OFFICE_ARTICULOS: ParentPaths.PATH_BACK_OFFICE + '/articulos',

    /*  ***********    */

    ENDPOINT_PDF: environment.apiUrl,

    SESION_CLIENTE: 'cliente',
    SESION_CUESTIONARIO: 'cuestionario',
    SESION_CUESTIONARIO_SALUD_INDIVIDUAL: 'cuestionarioSaludIndividual',
    SESION_LEAD: 'lead',
    SESION_AGENTE_LOGADO: 'agenteLogado',
    SESION_CLIENTE_LOGADO: 'clienteLogado',
    SESION_POLIZA: 'poliza',

    TIPO_OPERACION_CAMBIAR_BENEFICIARIOS: 'cambiarBeneficiarios',
    TIPO_OPERACION_CANCELAR_POLIZA: 'cancelarPoliza',
    TIPO_OPERACION_REDUCIR_CAPITAL: 'reducirCapital',
    TIPO_OPERACION_AMPLIAR_CAPITAL: 'ampliarCapital',
    TIPO_OPERACION_MODIFICAR_FORMA_PAGO: 'modificarFormaPago',

    PLANTILLA_EMAIL_COLECTIVO_ID: environment.idPlantillaEmailColectivo,
    PLANTILLA_EMAIL_COMPANNIA_ID: environment.idPlantillaEmailCompannia,
    PLANTILLA_EMAIL_BENEFICIARIOS_ID: environment.idPlantillaEmailBeneficiarios,
    PLANTILLA_EMAIL_RESETPASSWORD_ID: environment.idPlantillaEmailEstablecerContrasennia,
    PLANTILLA_EMAIL_SOLICITUD_ID: environment.idPlantillaEmailSolicitud,
    PLANTILLA_EMAIL_ACCION_AGENTE: environment.idPlantillaEmailGeneralAccionesAgente,
    PLANTILLA_EMAIL_ACCION_CLIENTE: environment.idPlantillaEmailGeneralAccionesCliente,
    PLANTILLA_EMAIL_SOLICITUD_FIRMA_ID: environment.idPlantillaEmailSolicitudFirma,
    PLANTILLA_EMAIL_INDIVIDUAL_RESETPASSWORD_ID: environment.idPlantillaIndividualEstablecerContrasennia,
    PLANTILLA_EMAIL_INDIVIDUAL_BIENVENIDO_ID: environment.idPlantillaEmailIndividualBienvenido,
    PLANTILLA_EMAIL_INDIVIDUAL_COMPANNIA_ID: environment.idPlantillaEmailCompanniaIndividual,
    PLANTILLA_EMAIL_BENEFICIARIOS_INDIVIDUAL_ID: environment.idPlantillaEmailBeneficiariosIndividual,
    PLANTILLA_EMAIL_ERROR_PAGO_ID: environment.idPlantillaEmailErrorPago,
    PLANTILLA_EMAIL_ERROR_FIRMA_LOGALTY_ID: environment.idPlantillaEmailErrorFirmaLogalty,
    PLANTILLA_EMAIL_CONTACTO_USUARIO_ID: environment.idPlantillaEmailContactoUsuario,

    BUZON_CORREOS_GESTORIA: environment.emailCompania,

    CUESTIONARIO_COLECTIVO_POR_DEFECTO_ID: 1,

    TIPO_SEGURO_COLECTIVO_ID: 1,
    TIPO_SEGURO_INDIVIDUAL_ID: 2,

    TIPO_ESTADO_PENDIENTE_ID: 1,
    TIPO_ESTADO_ACTIVO_ID: 2,
    TIPO_ESTADO_CANCELADO_ID: 3,

    // Roles
    ROL_ADMIN: 'ROL_ADMIN',


    // maestras
    TIPO_POLIZA_FAMILIA: 1,
    TIPO_POLIZA_HIPOTECA: 2,

    // Tiempo de espera en milisegundos
    TIEMPO_ESPERA: 500,

    //Agente individual
    NOMBRE_AGENTE_INDIVIDUAL: "José Herrero",
    TELEFONO_AGENTE_INDIVIDUAL: "910 052 147",
    EMAIL_AGENTE_INDIVIDUAL: "marketing@astraseguros.com",
    EMAIL_AGENTE_CONTACTO: "administracion@astraseguros.com",

    // Cuestionario salud individual
    PROFESION_SIN_RIESGO_ID: 1,


    //Valor de rechazo de la póliza indvidual
    VALOR_AGRAVAMIENTO_RECHAZO: -1,



    EOF: 'Fin del fichero, insertar por encima'
};

export const ConstantesApp = { ...ConstantesCore, ...ConstantesPersonalizadas };
