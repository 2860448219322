import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-confirmacion',
    templateUrl: './modal-confirmacion.component.html',
})
export class ModalConfirmacionComponent {
    constructor(
        public dialogRef: MatDialogRef<ModalConfirmacionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog
    ) {}

    clickCancel(): void {
        this.dialogRef.close(false);
    }

    clickOk() {
        this.dialogRef.close(true);
    }
}
