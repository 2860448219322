import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from '../app.component';
import { AppRoutingModule } from './app-routing.module';


import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { setAppInjector } from '../app.injector';
import { ModalConfirmacionComponent } from '../core.components/modal-confirmacion/modal-confirmacion.component';
import { ModalInformativoComponent } from '../core.components/modal-informativo/modal-informativo.component';
import { DateInterceptor } from '../core.utilidades/interceptors/date-interceptor';
import { GraphQLModule } from './graphql.module';

registerLocaleData(localeEs, 'es');

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        // App
        AppComponent,

        // Componentes core
        ModalInformativoComponent,
        ModalConfirmacionComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // FormsModule,
        GraphQLModule,
        MatIconModule, // mat-icon
        MatDialogModule, //mat-dialog-*
        MatSelectModule,
        AngularEditorModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatNativeDateModule,
        // ModuloClienteComunModule,
        // UserModule,

        ToastrModule.forRoot({
            maxOpened: 1, // Solamente uno a la vez
            autoDismiss: true,
            preventDuplicates: true,
            positionClass: 'toast-bottom-right',
            timeOut: 4000
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
                },
                deps: [HttpClient],
            },
        }),

        //routing module
        AppRoutingModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
        DatePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    
    constructor( injector: Injector ) {
        setAppInjector(injector);
    }

 }
