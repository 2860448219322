import { NamedType } from "../core.models/named-type";
import { getFieldsFromInstance } from "../core.utilidades/graphql-utils";

export class Agente implements NamedType {
    _className = 'Agente';

    id?: number;
    credencialId?: number;
    nombreApellidos: string;
    telefono: string;
    email: string;
    activo: boolean;

    constructor() {
        this.nombreApellidos = '';
        this.telefono = '';
        this.email = '';
        this.activo = true;
    }

    public static getFields = () => {
        return getFieldsFromInstance(new Agente());
    };
}