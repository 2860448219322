import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalData } from 'src/app/core.models/modal/modalData';
import { Utilidades } from 'src/app/core.utilidades/utilidades';
import { ModalFormularioGenericoComponent } from '../components/modal-formulario-generico/modal-formulario-generico.component';

@Injectable({
    providedIn: 'root',
})
export class GenericFormEventHandlerService {

    constructor(protected matDialog: MatDialog) { }

    // Implementacion del metodo que muestra el modal en la aplicacion
    modalFormularioGenerico(data: ModalData) {
        // Comprobar si existe el diálogo previamente
        const id = 'modal-formulario-generico';
        const dialog = this.matDialog.getDialogById(id);
        if (!dialog) {
            const dialogConfig = new MatDialogConfig();
            // The user can't close the dialog by clicking outside its body
            dialogConfig.disableClose = true;
            dialogConfig.id = id;
            dialogConfig.panelClass = ['modal-lateral', 'modal-lateral-estrecho'];

            const instanciaClone = data.instancia ? Utilidades.clonarObjeto(data.instancia) : null;
            dialogConfig.data = {
                entity: instanciaClone,
                form: data.formulario,
                modo: data.modo,
                formTitle: data.titulo,
                subTitulo: data.subTitulo,
                selectedEntity: data.select
            };
            const modalDialog = this.matDialog.open(ModalFormularioGenericoComponent, dialogConfig);
            modalDialog.afterClosed().subscribe((result) => {
                this.modalCallback(result, data);
            });
        }
    }

    /**
     * Llamada de respuesta del modal
     */
    modalCallback(result: any, data: any) {
        // si result viene relleno significa que ha entrado por ok
        if (result) {
            if (data.callback) {
                if (data.callbackParams) {
                    // eslint-disable-next-line no-eval
                    data.callback(eval(data.callbackParams), data.contexto);
                } else {
                    data.callback(data.contexto);
                }
            }
        } else {
            if (data.callbackCancelar) {
                if (data.callbackCancelarParams) {
                    // eslint-disable-next-line no-eval
                    data.callbackCancelar(eval(data.callbackCancelarParams), data.contexto);
                } else {
                    data.callbackCancelar(data.contexto);
                }
            }
        }
    }

}
