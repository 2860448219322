import { Component } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConstantesApp } from 'src/app/app.config/constantes-app';
import { Agente } from 'src/app/app.domain.models/agente';
import { CoreEvents } from 'src/app/core.config/core-events';
import { DialogData } from 'src/app/core.models/modal/dialogData';
import { EventBrokerService } from 'src/app/core.services/event-broker.service';
import { UserService } from 'src/app/core.services/user.service';
import { SesionService } from 'src/app/core.services/sesion.service';

export const FORMATO_DATE_MES = {
    display: {
        dateInput: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-header-back-office',
    templateUrl: './header-back-office.component.html',
    providers: [{ provide: MAT_DATE_FORMATS, useValue: FORMATO_DATE_MES }],
})
export class HeaderBackOfficeComponent {

    public agenteLogado: Agente;
    public nombreCompleto: string = '';

    constructor(
        private router: Router,
        private loginService: UserService,
        private eventBrokerService: EventBrokerService,
        private sesionService: SesionService,
        public dialog: MatDialog,
    ) {
        this.agenteLogado = this.sesionService.getAgenteLogado();
    }

    logout() {
        const actionTitle = 'login.cerrarSesion';
        const modalData: DialogData = {
            titulo: actionTitle,

            contexto: this,
            callback: (contexto: HeaderBackOfficeComponent) => {
                contexto.loginService.logout().then(() => {
                    contexto.router.navigate([ConstantesApp.PATH_USER_LOGIN]);
                })
            },
        };
        this.eventBrokerService.publishEvent(CoreEvents.modalConfirm, modalData);

    }

    login() {
        this.router.navigateByUrl(ConstantesApp.PATH_USER_LOGIN);
    }
}
